import React from "react";
import { useRouteError } from "react-router-dom";

export const POSSIBLE_ERROR_STRINGS = [
	`This isn't the error you're looking for.`,
	`that's all we know... wait we should probably know more than that?`,
	`Ruh roh, Raggy, an error!`,
	`You shall not pass... this error!`,
];

export const BUG_REPORT_LINK = "https://wunderkindco.atlassian.net/servicedesk/customer/portal/25/group/72/create/302";

const getFlavorText = () => POSSIBLE_ERROR_STRINGS[Math.floor(Math.random() * POSSIBLE_ERROR_STRINGS.length)];

const ErrorBoundary = () => {
	const { message } = useRouteError() as Error;

	return (
		<div className="App">
			<h1>
				An error occurred:
			</h1>
			<pre>
				{message}
			</pre>
			<h2 data-qa="error-flavor-text">
				{getFlavorText()}
			</h2>
			<h2>
				<a href={BUG_REPORT_LINK}>
					Submit a bug report here
				</a>
			</h2>
		</div>
	);
};

export default ErrorBoundary;
