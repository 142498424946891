import React, { MouseEventHandler, PropsWithChildren } from "react";
import classnames from "classnames";

import { WithDataQa } from "@frontend/thorium232/dist/types/base";

export interface ListColumnHeaderProps extends WithDataQa, PropsWithChildren {
	sortable?: boolean;
	sortDirection?: "asc" | "desc";
	onClick?: MouseEventHandler
}

// removed sorting icon
const ListColumnHeader = ({
	sortable, sortDirection, children, onClick, dataQa = "list-column-header",
}: ListColumnHeaderProps) => (
	<div
		onClick={onClick}
		className={classnames("list-col-header", {
			"list-col-header__sort": sortable,
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			[`list-col-header__sort-${sortDirection}`]: sortDirection,
		})}
		role="presentation"
		data-qa={dataQa}
	>
		{children}
	</div>
);

export default ListColumnHeader;
