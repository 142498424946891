import React from "react";
import { useSelector } from "react-redux";
import { createSearchParams } from "react-router-dom";

import { Icon, Typography } from "@frontend/wknd-components";

import { getSearchState } from "../search/search.selector";
import { SearchTypes } from "../search/search.slice";

import { StyledDeviceLink } from "./Artifacts.style";

const ToDeviceIdButton = ({ deviceId }: { deviceId: string }) => {
	const { websiteid } = useSelector(getSearchState);
	const searchParams = createSearchParams(
		{ websiteId: websiteid, searchType: SearchTypes.DEVICE_ID, searchInput: deviceId },
	).toString();


	return (
		<StyledDeviceLink
			to={`/results?${searchParams}`}
		>
			<Icon name="Search" size={16} />
			<Typography
				variant="bodyCopyBold"
				component="span"
				aria-label="device id"
				dataQA="deviceid">
				{deviceId}

			</Typography>
		</StyledDeviceLink>
	);
};

export default ToDeviceIdButton;
