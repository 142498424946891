import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DevicesFromPrivacyId } from "./types";

export const enum PrivacySearchTypes {
	EMAIL = "email",
	EMAIL_HASH = "email_hash",
	PHONE = "phone",
	PHONE_HASH = "phone_hash",
	DEVICE_ID = "deviceid",
	SOFT_ID = "softid",
	DEVICE_TOKEN = "devicetoken",
	PRIVACY_ID = "privacyid",
	KLAVIYO_ID = "klaviyoid",
	ATTENTIVE_ID = "attentiveid",
	BLUE_CONIC_ID = "blueconicid",
	LOYALTY_PLUS_ID = "loyaltyplusid",
	CUSTOMER_ID = "customerid",
}

export type InitialPrivacyState = {
	searchType: PrivacySearchTypes;
	websiteid: string;
	input: string;
	results: DevicesFromPrivacyId[] | null;
	deviceIds: DevicesFromPrivacyId[] | null;
	privacyIds: DevicesFromPrivacyId[] | null;

};

const privacy: InitialPrivacyState = {
	searchType: PrivacySearchTypes.EMAIL,
	websiteid: "",
	input: "",
	results: null,
	deviceIds: null,
	privacyIds: null,
};

const initialState: InitialPrivacyState = { ...privacy };

const privacyLookUpSlice = createSlice({
	name: "privacyLookUp",
	initialState,
	reducers: {
		setResults: (state, action: PayloadAction<DevicesFromPrivacyId[]>) => ({ ...state, results: action.payload }),
		setDeviceIdResults: (state, action: PayloadAction<DevicesFromPrivacyId[]>) => ({ ...state,
			deviceIds: action.payload }),
		setPrivacyIdResults: (state, action: PayloadAction<DevicesFromPrivacyId[]>) => ({ ...state,
			privacyIds: action.payload }),
		setInput: (state, action: PayloadAction<string>) => ({
			...state,
			input: action.payload,
		}),
		setSearchType: (state, action: PayloadAction<PrivacySearchTypes>) => ({ ...state, searchType: action.payload }),
		clearPrivacy: () => ({
			...initialState,
		}),
	},
});

export const {
	setResults,
	setInput,
	setSearchType,
	clearPrivacy,
	setDeviceIdResults,
	setPrivacyIdResults,
} = privacyLookUpSlice.actions;

export default privacyLookUpSlice.reducer;

