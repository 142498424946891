import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Event } from "./types";

export const enum SearchTypes {
	AUTO = "auto",
	EMAIL = "email",
	EMAIL_HASH = "email_hash",
	DEVICE_ID = "deviceid",
	PHONE = "phone",
	PHONE_HASH = "phone_hash",
}

export const enum FilterBys {
	EVENT_TYPE = "eventname",
	DEVICE_ID = "deviceid",
	CAMPAIGN_ID = "campaignid,parentcampaignid", // keys separated by comma search for values in each of them
	VISIT_ID = "visitid",
	ITEM_ID = "item:id",
	EVENT_ID = "eventid",
}

export const isMultiKey = (key: FilterBys, separator = ","): boolean => key.indexOf(separator) !== -1;
export const splitKeys = (key: FilterBys, separator = ","): string[] => (key as string).split(separator);
export const filterValuesAreEqual = (o1: FilterValue, o2: FilterValue) =>
	Object.keys(o1).length === Object.keys(o2).length && o1.value === o2.value;
export const filterValueArraysAreEqual = (a1: FilterValue[], a2: FilterValue[]) =>
	a1.length === a2.length && a1.every((o, idx) => filterValuesAreEqual(o, a2[idx]));

export const emptyStrings: { [key: string]: string } = {
	[FilterBys.CAMPAIGN_ID]: "(no campaign)",
	[FilterBys.ITEM_ID]: "(no item id)",
	[FilterBys.VISIT_ID]: "(no visit id)",
};

export type FilterValue = { label?: string, value?: string };

export type FilterData = { [K in FilterBys]?: FilterValue | FilterValue[] };

export const isMultiValue = (filter: FilterValue | FilterValue[] | undefined): boolean => "length" in (filter || {});

export const isFilterEmpty = (filters: FilterData | null) => filters === null || Object.keys(filters).length === 0
|| Object.keys(filters).every((key) => (
	isMultiValue(filters[key as FilterBys]) ?
		(filters[key as FilterBys] as FilterValue[]).length === 0
		: !(filters[key as FilterBys] as FilterValue)?.value
));

export type DatetimeFilterData = {
	start?: number | null,
	end?: number | null,
};

export type SearchState = {
	/** Type of search that defines the kind of input used to get events */
	type: SearchTypes;
	/** ID of the website where the search is being performed */
	websiteid: string;
	/**
	 * Name of website
	 */
	websitename: string;
	/** Result of the search with the events found */
	result: Event[] | null;
	/** Filters used to generate filteredResult from result */
	filters: FilterData | null;
	/** Threshold dates to be filtered */
	datetimeFilter: DatetimeFilterData | null;
	/** Result filtered */
	filteredResult: Event[] | null;
	/** Search input */
	input: string;
	lastActiveDevices: Record<string, number>;
};

const search: SearchState = {
	type: SearchTypes.AUTO,
	websiteid: "",
	websitename: "",
	result: null,
	filters: {},
	datetimeFilter: null,
	filteredResult: null,
	input: "",
	lastActiveDevices: {},
};

const initialState: SearchState = { ...search };

const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		setType: (state, action: PayloadAction<SearchTypes>) => ({ ...state, type: action.payload }),
		setInput: (state, action: PayloadAction<string>) => ({
			...state,
			input: action.payload,
		}),
		setWebsite: (state, action: PayloadAction<{
			name: string,
			id: string,
		}>) => {
			const { name, id: websiteid } = action.payload;

			let websitename = name;

			// the WebsitePicker component displays values in the dropdown as "Website Name - Website ID"
			// it could also have a hyphen in the actual Website Name as well
			[websitename] = websitename.split(` - ${websiteid}`);

			if (Number.isNaN(parseInt(action.payload.id, 10))) {
				return state;
			}

			return {
				...state,
				websiteid,
				websitename,
			};
		},
		setResult: (state, action: PayloadAction<Event[] | null>) => ({ ...state, result: action.payload }),
		setFilters: (state, action: PayloadAction<FilterData | null>) => {
			if (action.payload === null || Object.keys(action.payload).length === 0) {
				return {
					...state,
					filters: null,
				};
			}

			return {
				...state,
				filters: {
					...state.filters,
					...action.payload,
				},
			};
		},
		setDatetimeFilter: (state, action: PayloadAction<(DatetimeFilterData | null) | null>) => {
			if (action.payload === null) {
				return {
					...state,
					datetimeFilter: null,
				};
			}

			if (action.payload.start && action.payload.end && action.payload.start > action.payload.end) {
				return state;
			}

			return {
				...state,
				datetimeFilter: action.payload,
			};
		},
		applyFilters: (state) => {
			const filters = { ...state.filters };
			const datetimeFilter = { ...state.datetimeFilter };
			const isDatetimeFilterEmpty = datetimeFilter === null || (!datetimeFilter.start && !datetimeFilter.end);

			if (state.result === null || (isFilterEmpty(filters)  && isDatetimeFilterEmpty)) {
				return {
					...state,
					filteredResult: null,
				};
			}

			const minDatetime = datetimeFilter?.start || 0;
			const maxDatetime = datetimeFilter?.end || Number.MAX_VALUE;

			const filterByValues = (event: Event) => (Object.keys(filters).every(
				(key) => {
					if (isMultiKey(key as FilterBys)) {
						const filterValues = splitKeys(key as FilterBys).map((filterKey) => event[filterKey])
							.filter((value) => !!value);

						return !(filters[key as FilterBys] as FilterValue)?.value
							|| filterValues.includes((filters[key as FilterBys] as FilterValue)?.value as string)
							|| ((filters[key as FilterBys] as FilterValue)?.value === emptyStrings[key]
								&& filterValues.length === 0);
					}

					if (isMultiValue(filters[key as FilterBys])) {
						return (filters[key as FilterBys] as FilterValue[]).length === 0
							|| (filters[key as FilterBys] as FilterValue[]).some((filter) => (
								filter.value === event[key] || (filter.value === emptyStrings[key] && !event[key])
							));
					}

					return !(filters[key as FilterBys] as FilterValue)?.value
						|| (filters[key as FilterBys] as FilterValue).value === event[key]
						|| ((filters[key as FilterBys] as FilterValue).value === emptyStrings[key] && !event[key]);
				},
			));

			const filterByDatetimes = (timestamp: number) => !datetimeFilter
				|| (minDatetime <= timestamp && timestamp <= maxDatetime);

			const filteredResult: Event[] = state.result.filter((event) => (
				filterByValues(event) && filterByDatetimes(+event.timestamp)
			));

			return {
				...state,
				filteredResult,
			};
		},
		setLastActiveDevices: (state, action: PayloadAction<Record<string, number>>) => ({
			...state,
			lastActiveDevices: action.payload,
		}),
		clearSearch: () => ({
			...initialState,
		}),
	},
});

export const {
	setType,
	setWebsite,
	setResult,
	applyFilters,
	setFilters,
	setInput,
	setDatetimeFilter,
	setLastActiveDevices,
	clearSearch,
} = searchSlice.actions;

export default searchSlice.reducer;

