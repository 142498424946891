import React from "react";

import { SideBar as ThoriumSideBar, SideBarGroup } from "@frontend/thorium232/dist/layout";

import "./SideBar.scss";

/**
 * Wrapper for Thorium232 SideBar
 * TODO: Add VerticalMenu here
 */
const SideBar = ({ children }: React.PropsWithChildren) => (
	<ThoriumSideBar	>
		<SideBarGroup>
			{children}
		</SideBarGroup>
	</ThoriumSideBar>
);

export default SideBar;
