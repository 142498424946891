import React from "react";

import ThoriumButton, { ButtonProps } from "@frontend/thorium232/dist/form-elements/Button";

/**
 * Wrapper for Thorium232 Button
 */
const Button = ({
	type = "button",
	children,
	buttonStyle,
	onClick,
	...rest
}: ButtonProps) => (
	<ThoriumButton buttonStyle={buttonStyle} type={type} onClick={onClick} {...rest}>
		{children}
	</ThoriumButton>
);

export default Button;
