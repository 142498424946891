/* eslint-disable indent */
import React from "react";
import { IAceEditorProps } from "react-ace";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import styled from "styled-components";

import { Column, Row } from "@frontend/thorium232/dist/fieldset";
import { CenterOptions, SizeOptions } from "@frontend/thorium232/dist/types/base";
import { Button, Checkbox } from "@frontend/wknd-components";

import Editor from "../editor/Editor";
import Input, { InputProps } from "../form/Input";

import { EligibilityFormData } from "./Eligibility";

const StyledDiv = styled.div`
	width: 85%;
`;
export interface EligibilityEditorProps<T> extends UseControllerProps<T & FieldValues> {
	buttonText: string;
	buttonOnClick: () => void;
	labelName: string;
	editorMode: string;
	editorOnChange: IAceEditorProps["onChange"]
	checkboxName?: Path<T & FieldValues>;
	checkboxLabel?: string;
	input?: InputProps<T & EligibilityFormData>;
}


const EligibilityEditor = <T extends FieldValues>({
	name,
	control,
	buttonText,
	buttonOnClick,
	labelName,
	editorMode,
	editorOnChange,
	checkboxName,
	checkboxLabel,
	input,
}: EligibilityEditorProps<T>) => (
			<StyledDiv>
				<div className="label-wrapper">
					<label
						className="text-input_label text-input_label__type-text"
					>
						{labelName}
					</label>
				</div>
				{
					checkboxName &&
					<Controller
						name={checkboxName}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Checkbox
								onChange={onChange}
								checked={value}
								label={checkboxLabel}
								dataQA={`${checkboxName}-checkbox`}
							/>
						)}
					/>
				}
				<Controller
					name={name}
					control={control}
					render={({ field }) => (
						<Editor
							{...field}
							dataQA={name}
							id={name}
							mode={editorMode}
							className="styledEditor"
							onChange={editorOnChange}
						/>
					)}
				/>
				<Row>
					<Column>
						{input &&
							<Input
								{...input}
							/>
						}
					</Column>
					<Column size={SizeOptions.CONTENT} center={CenterOptions.HORIZONTAL}>
						<Button
							type="button"
							variant="primary"
							buttonText={buttonText}
							className="styledButton"
							dataQA={buttonText.toLowerCase().replaceAll(" ", "-")}
							onClick={buttonOnClick}
						/>
					</Column>
				</Row>
			</StyledDiv>
);

export default EligibilityEditor;
