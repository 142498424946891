import React from "react";
import { Link } from "react-router-dom";

import { HeaderBar as ThoriumHeaderBar } from "@frontend/thorium232/dist/layout";

interface HeaderBarProps {
	title?: string;
	customTitle?: true;
	children?: React.ReactNode;
}

const HeaderBar = ({
	title = "UHD 2.0",
	customTitle,
	children,
}: HeaderBarProps) => {
	if (customTitle) {
		return (
			<ThoriumHeaderBar>
				<h1>{title}</h1>
			</ThoriumHeaderBar>
		);
	}

	if (children) {
		return <ThoriumHeaderBar>
			{children}
		</ThoriumHeaderBar>;
	}

	const titleLink = <><Link to="/">UHD 2.0</Link>{title !== "UHD 2.0" && ` | ${title}`}</>;

	return (
		<ThoriumHeaderBar>
			<h1>{titleLink}</h1>
		</ThoriumHeaderBar>
	);
};

export default HeaderBar;
