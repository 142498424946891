import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { Button } from "@frontend/wknd-components";

import { useLazyGetEmailEventsQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import { Combobox, Input } from "../form/";
import { clearPrivacy } from "../privacy-id-look-up/privacy.slice";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import useWebsites from "../websites/useWebsites";
import WebsitePicker from "../websites/WebsitePicker";

import { setInitialSearch } from "./initialSearch.slice";
import { getSearchState } from "./search.selector";
import {
	SearchTypes,
	setInput,
	setType,
	setWebsite,
} from "./search.slice";
import { DivSearchFields, SearchButtonWrapper, StyledCard, StyledRow } from "./Search.styles";
import { detectType, validateEmail, validateNumber } from "./searchTypes";

import "./Search.scss";

interface SearchForm {
	input: string;
	websiteid: {
		label: string;
		value: string;
	};
	type: { label: string, value: SearchTypes };
}

const typeOptions = [
	{ value: SearchTypes.AUTO, label: "Auto-Detect" },
	{ value: SearchTypes.EMAIL, label: "Email" },
	{ value: SearchTypes.EMAIL_HASH, label: "Email Hash" },
	{ value: SearchTypes.DEVICE_ID, label: "Device ID" },
	{ value: SearchTypes.PHONE, label: "Phone" },
	{ value: SearchTypes.PHONE_HASH, label: "Phone Hash" },
];


const Search = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [URLParams] = useSearchParams();
	const { getWebsiteById } = useWebsites();
	const {
		websiteid: searchStateWebsiteId = "",
		websitename: searchStateWebsiteName = "",
		input: searchStateInput,
		type: searchStateType,
	} = useSelector(getSearchState);

	const websiteId = URLParams.get("websiteId") ?? searchStateWebsiteId;
	const searchType = URLParams.get("searchType");
	const searchInput = URLParams.get("searchInput");
	const websiteName = getWebsiteById(websiteId)?.name ?? "";

	const {
		register,
		control,
		watch,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({
		mode: "all",
		defaultValues: {
			input: searchStateInput || "",
			type: typeOptions.find((typeOption) => typeOption.value === searchStateType) || typeOptions[0],
			websiteid: {
				label: formatWebsiteLabel({ label: websiteName, value: websiteId }) || searchStateWebsiteName || "",
				value: searchStateWebsiteId || "",
			},
		},
	});


	useEffect(() => {
		const isSearchStateEmpty = searchStateWebsiteId === "" && searchStateInput === "";
		const hasSearchParams = websiteId !== "" && searchInput && searchType !== "";
		if (isSearchStateEmpty && hasSearchParams) {
			dispatch(setWebsite({
				id: websiteId,
				name: websiteName,
			}));

			dispatch(setType(searchType as SearchTypes));
			dispatch(setInput(searchInput));
			reset({
				input: searchInput,
				type: typeOptions.find((typeOption) => typeOption.value === searchType),
				websiteid: {
					label: formatWebsiteLabel({ label: websiteName, value: websiteId }),
					value: websiteId,
				},
			});
		}
	}, [
		searchStateInput,
		searchStateType,
		searchStateWebsiteName,
		searchStateWebsiteId,
		reset,
		websiteId,
		searchInput,
		searchType,
		dispatch,
		websiteName,
	]);

	const [getEmailEvents] = useLazyGetEmailEventsQuery();

	const currentType = watch("type");

	const getInputPlaceholder = (type: SearchTypes): string => {
		switch (type) {
			case SearchTypes.EMAIL:
				return "User Email...";
			case SearchTypes.EMAIL_HASH:
				return "User Email Hash...";
			case SearchTypes.DEVICE_ID:
				return "User Device...";
			case SearchTypes.PHONE:
				return "User Phone...";
			default:
				return "User Email, Hash, Device or Phone...";
		}
	};

	const hasEmailHashResults = async (input: string, websiteid: string): Promise<boolean> => {
		try {
			const res = await getEmailEvents({ emailhash: input, websiteid }).unwrap();

			return res.success && "events" in res && res.events.length > 0;
		} catch {
			return false;
		}
	};

	const goToResultPage = async (data: SearchForm) => {
		const { input, websiteid } = data;
		const { value: typeValue } = data.type;
		let effectiveType: SearchTypes;
		if (typeValue === SearchTypes.AUTO) {
			try {
				effectiveType = await detectType(input, async () => hasEmailHashResults(input, websiteid.value));
			} catch {
				return catchErrorReturnString();
			}
		} else {
			effectiveType = typeValue;
		}
		dispatch(clearPrivacy());
		dispatch(setInitialSearch({
			input,
			websiteid: websiteid.value,
			type: effectiveType,
		}));

		const searchParams = createSearchParams(
			{ websiteId: websiteid.value, searchType: effectiveType, searchInput: input },
		).toString();
		navigate({ pathname: "/search", search: searchParams });
	};

	return (
		<StyledCard dataQA="search-card" ariaLabel="search-card">
			<StyledRow>
				<DivSearchFields>
					<WebsitePicker control={control} error={errors.websiteid} label="Website ID / Name" />
				</DivSearchFields>
				<DivSearchFields>
					<Input
						label="Email / Device / Phone"
						name="input"
						required
						placeholder={getInputPlaceholder(currentType.value)}
						dataQA="type-input"
						id="input-type"
						register={register}
						validators={{
							required: "User Input is required",
							validate: {
								isEmail: (v) => getValues("type").value !== SearchTypes.EMAIL || validateEmail(v as string)
									|| "Invalid Email",
								isDeviceId: (v) => getValues("type").value !== SearchTypes.DEVICE_ID || validateNumber(v as string)
									|| "Device ID must be a number",
								isPhone: (v) => getValues("type").value !== SearchTypes.PHONE || validateNumber(v as string)
									|| "Phone must be a number",
							},
						}}
						error={errors.input}
					/>
				</DivSearchFields>
				<DivSearchFields>
					<Combobox
						dataQA="type-select"
						label="Type"
						name="type"
						control={control}
						options={typeOptions}
					/>
				</DivSearchFields>
				<SearchButtonWrapper>
					<Button
						variant="primary"
						buttonText="Search"
						onClick={handleSubmit(goToResultPage)}
						dataQA="search-button"
						mt="1.6rem"
					/>
				</SearchButtonWrapper>
			</StyledRow>
		</StyledCard>
	);
};

export default Search;
