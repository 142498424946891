/* eslint-disable */
import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { AnyAction, isRejectedWithValue } from "@reduxjs/toolkit";

import { appendNotification } from "../notifications/notifications.slice";
import { handleAuthenticationError } from "@frontend/authentication";

export const rtkQueryErrorLogger: Middleware = ({ dispatch }: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if(action.payload.status === 401){
			handleAuthenticationError();
		}

		const isServerError = action.payload.status >= 500
		const payloadMessage = action.payload?.data?.errormessage || action.payload?.data; 
		const notificationText = typeof payloadMessage == "string" && payloadMessage != "" ? payloadMessage : "Request error, try again";
		dispatch(appendNotification({
			id: 0,
			text: notificationText,
			variant: isServerError ? "Error" : "Warning",
			hasTimeout: isServerError ? false : true,
			timeout: 10000,
			trailingLinkText: "Details",
			details: JSON.stringify(action.payload, undefined, 4),
		}) as unknown as AnyAction);
	}

	return next(action);
};

export const catchErrorReturnString = () : string => {
	return "";
}

