import { handleAuthentication, initializeAuthentication } from "@frontend/authentication";

import { useGetUserInfoQuery } from "../app/api";
import { Website } from "../app/types";

type Websites = Website[] | undefined | null;

const useWebsites = () => {
	let websites: Websites = [];
	const userInfo = useGetUserInfoQuery();

	const user = window.wkndAuthentication.getUserFromStorage();

	if (user === null) {
		initializeAuthentication().then(() => handleAuthentication());

	} else {
		if (userInfo.data && "websites" in userInfo.data &&
			userInfo.data.websites.length > 0 && user.permissions.role === "role_dev") {
			websites = userInfo.data.websites.
				map((website) => ({ name: website.name, id: String(website.id) }));
		} else {
			({
				permissions: {
					websites,
				},
			} = user);
		}
	}

	const getWebsites = () => websites;
	const getWebsiteById = (id: string): Website | undefined => websites?.find((website) => website.id === id);
	const getWebsiteByName = (name: string): Website | undefined => websites?.find((website) => website.name === name);

	return {
		getWebsites,
		getWebsiteById,
		getWebsiteByName,
	};
};

export default useWebsites;
