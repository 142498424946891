import React from "react";
import { FieldValues, Path } from "react-hook-form";
import styled from "styled-components";

import { validateEmail } from "../search/searchTypes";
import WebsitePicker, { WebsitePickerProps } from "../websites/WebsitePicker";

import Input, { InputProps } from "./Input";

const StyledDiv = styled.div`
	margin-bottom: 1rem;
`;
interface SessionInputsProps<T extends FieldValues> {
	websiteProps: Pick<WebsitePickerProps<T>, "control" | "error">
	emailProps: Pick<InputProps<T>, "error" | "register"> &
	{
		required?: boolean | Record<string, unknown>,
		placeholder?: string
	}
}

const getValidators = (validators?: SessionInputsProps<FieldValues>["emailProps"]["required"]) => {
	if (!validators) {
		return;
	}

	if (typeof validators === "boolean" && validators === true) {
		return {
			required: "An email is required",
			validate: {
				isEmail: (v: string) => validateEmail(v) || "Invalid email address",
			},
		};
	}

	return validators;
};

const SessionInputs = <T extends FieldValues>({
	websiteProps: {
		control,
		error: websiteError,
	},
	emailProps: {
		error: emailError,
		register,
		required = true,
		placeholder = "Email",
	},
}: SessionInputsProps<T>) => <>
		<StyledDiv>
			<WebsitePicker
				control={control}
				error={websiteError}
				label="Website ID / Name"
			/>
		</StyledDiv>
		<Input
			label="Email"
			name={"email" as Path<T>}
			error={emailError}
			validators={getValidators(required)}
			id="email"
			dataQA="email-input"
			placeholder={placeholder || "Email"}
			register={register}
			required={typeof required === "boolean"}
		/>
	</>;

export default SessionInputs;
