import React, { useState } from "react";
import styled from "styled-components";

import { Row } from "@frontend/thorium232/dist/fieldset";
import { SizeOptions, WithDataQa } from "@frontend/thorium232/dist/types/base";
import { colors, DataTable, LinkButton, Typography } from "@frontend/wknd-components";
import { nanoid } from "@reduxjs/toolkit";

import { TIMEZONE_LABEL } from "../app/constants";
import formatResultTimestamp from "../date-picker/formatResultTimestamp";
import { ListColumn, ListRow } from "../list";

import { Event } from "./types";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	margin-top: 32px;

	.rdt_TableRow.warning {
		border: 3px solid ${colors.yellow};
	}

	.rdt_TableRow.warning:hover {
		border: 3px solid ${colors.yellow};
		background-color: ${colors.lightestGray};
	}
`;

interface ResultsProps extends WithDataQa {
	events: Event[]
	hasSearch: boolean
}

const StyledLinkButton = styled(LinkButton)`
	float:right;
	width: 100%;
`;

const StyledDivider = styled.div`
	background-color: ${colors.lightGray};
	width: 100%;
	height: 1px;
	margin: 8px 0;
`;

const ExpandableRowComponent = ({ data }: { data: Record<string, unknown> }) => <ListRow
	paddingSize={SizeOptions.L}
	backgroundType={data.eventname === "error" || data.valid === "false" ? "warning-expanded" : "selected-expanded"}
	dataQa="list-row-expanded"
>
	<ListColumn size={SizeOptions.FILL}>
		{
			Object.entries(data).map(([key, value], index) => (
				<React.Fragment key={nanoid()}>
					{index > 0 ? <StyledDivider /> : null}
					<Row>
						<Typography component="span" dataQA="key" variant="bodyCopyBold">
							{key}:
						</Typography>
						<Typography dataQA="value" component="span" variant="bodyCopy" ml="10px">
							{value as string}
						</Typography>
					</Row>
				</React.Fragment>
			))
		}
	</ListColumn>
</ListRow>;

const HeaderButton = ({ allExpanded, toggleExpanded }: { allExpanded: boolean, toggleExpanded: () => void }) => <>
	<div className="list-header">
		Device ID
	</div>
	<StyledLinkButton
		buttonText={`${allExpanded ? "Collapse" : "Expand"} All`}
		onClick={toggleExpanded}
		dataQA={allExpanded ? "collapse-all-button" : "expand-all-button"}
		mr="0.25rem"
	/>
</>;

const customTimestampColumnSort = ({ timestamp: a }: Event, { timestamp: b }: Event) => a > b ? 1 : -1;

const Results = ({ events, hasSearch }: ResultsProps) => {
	const [allExpanded, setAllExpanded] = useState<boolean>(false);

	const toggleExpanded = () => {
		setAllExpanded((prev) => !prev);
	};

	const columns: Record<string, unknown>[] = [{
		name: `Timestamp (${TIMEZONE_LABEL})`,
		selector: (row: Event) => formatResultTimestamp(row.timestamp),
		sortable: true,
		sortFunction: customTimestampColumnSort,
	}, {
		name: "Event",
		selector: (row: Event) => row.eventname,
	}, {
		name: "Event ID",
		selector: (row: Event) => row.eventid,
	}, {
		name: <HeaderButton allExpanded={allExpanded} toggleExpanded={toggleExpanded} />,
		selector: (row: Event) => row.deviceid,
	}];


	return (
		<Container data-qa="event-results">
			<DataTable
				columns={columns}
				data={events}
				dataQA="results-datatable"
				expandableRowsComponent={ExpandableRowComponent}
				expandOnRowClicked
				expandableRows
				noDataHeader={hasSearch ? "No records found for this search" : "Perform search for results"}
				expandableRowExpanded={() => allExpanded}
				conditionalRowStyles={[{
					when: (data: Record<string, unknown>) => data.eventname === "error" || data.valid === "false",
					classNames: ["warning"],
				}]}
			/>
		</Container>
	);
};

export default Results;
