import { Event } from "../search/types";

const getCampaignIdsFromEvents = (
	arr: Event[],
) => arr.map(({ campaignid }) => campaignid);

export const getCampaignIds = ({
	phone,
	email,
}: {
	phone: Event[],
	email: Event[],
}) => ({
	phone: getCampaignIdsFromEvents(phone),
	email: getCampaignIdsFromEvents(email),
});

interface FilterForSubmitEvents {
	/**
	 * Events to filter through
	 */
	events: Event[],
	/**
	 * Defaults to "submit"
	 */
	eventNameToSearch?: string;
	/**
	 * Whether to filter out duplicate events
	 * Filtered by campaign ID
	 */
	unique?: true;
}

/**
 * Filter and structure submit events
 */
export default ({
	events,
	eventNameToSearch = "submit",
	unique = true,
}: FilterForSubmitEvents) => {
	const usedCampaigns: string[] = [];
	const defaultValue: {
		email: Event[],
		phone: Event[]
	} = {
		email: [],
		phone: [],
	};

	return events.filter(({ eventname }) => eventname === eventNameToSearch).reduce((prev, event) => {
		const { campaignid = "" } = event;

		if (unique && (!campaignid || usedCampaigns.indexOf(campaignid) !== -1)) {
			return prev;
		}

		usedCampaigns.push(campaignid);

		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		event?.["submit:phone_number"] ? prev.phone.push(event) : prev.email.push(event);

		return prev;
	}, defaultValue);
};

