/* eslint-disable no-console */
import React, { useEffect } from "react";
import AceEditor, { IAceEditorProps } from "react-ace";

import "ace-builds";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

interface EditorProps extends IAceEditorProps {
	/** id for the textarea */
	id: string
	dataQA: string
	name: string
}

/**
 * @description Editor is built using [`react-ace`](https://github.com/securingsincity/react-ace)
 */
const Editor = React.forwardRef<AceEditor, EditorProps>(({
	dataQA,
	id,
	...props
}, ref) => {
	useEffect(() => {
		// name is used as id on the outer div
		// see https://github.com/securingsincity/react-ace/blob/main/src/ace.tsx#L580
		const aceMainWrapper = document.getElementById(props.name);
		aceMainWrapper?.setAttribute("data-qa", dataQA);

		const textArea = aceMainWrapper?.firstElementChild;

		if (!textArea) {
			console.warn(`<textarea> with id ${props.name} not found`);
			return;
		}

		textArea.setAttribute("id", id);
		textArea.setAttribute("data-qa", `${dataQA}-textarea`);
	});

	return (
		<AceEditor
			theme="monokai"
			mode="json"
			ref={ref}
			width="auto"
			wrapEnabled
			showPrintMargin={false}
			{...props}
		/>
	);
});

Editor.displayName = "Editor";

export default Editor;
