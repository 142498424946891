import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { handleAuthentication, initializeAuthentication } from "@frontend/authentication";
import { BrowserTracing as SentryBrowserTracing, init as SentryInit, Replay as SentryReplay } from "@sentry/react";

import { ENVIRONMENT } from "./app/environment";
import routes from "./app/routes";
import { store } from "./app/store";
import ToastProvider from "./notifications/ToastProvider";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const VERSION_TAG = process.env.REACT_APP_VERSION_TAG;

// eslint-disable-next-line no-console
console.log(
	"%cUHD2",
	`color: #fff; background-color: #3d55cc; border-radius: 3px; padding: 5px; \
	font-family: Larsseit, "Helvetica Neue", Helvetica, Arial, sans-serif`,
	VERSION_TAG,
);

SentryInit({
	dsn: "https://3cc8d02567664355b3f7a14c641e5af8@o4505318531530752.ingest.sentry.io/4505354528817159",
	replaysOnErrorSampleRate: 0.1,
	replaysSessionSampleRate: 1.0,
	integrations: [new SentryBrowserTracing(), new SentryReplay()],
	tracesSampleRate: 1.0,
	environment: ENVIRONMENT,
	release: VERSION_TAG,
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const render = () => {
	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<ToastProvider>
					<RouterProvider router={routes} />
				</ToastProvider>
			</Provider>
		</React.StrictMode>,
	);
};


// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
initializeAuthentication()
	.then(() => handleAuthentication())
	.then(() => {
		render();
	})
	// eslint-disable-next-line no-console
	.catch(console.error);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
