import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { Button, LinkButton } from "@frontend/wknd-components";
import { AnyAction } from "@reduxjs/toolkit";

import { useLazyBatchSendEmailQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import Editor from "../editor/Editor";
import Input from "../form/Input";
import { Navigation } from "../navigation";
import { appendNotification } from "../notifications/notifications.slice";
import { getParsedSearchState } from "../search/search.selector";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";

import { BatchSendEmailFormData, BatchSendEmailJob, BatchSendEmailRequest } from "./types";

import "../send-email/SendEmail.scss";

type BatchSendEmailProps = {
	defaultEmailJobs?: string
};

const DEFAULT_JOB_INDEX = 1;
const DEFAULT_EMAIL_JOB: BatchSendEmailJob = {
	"Email": "YOUR.NAME@wunderkind.co",
	"From": "test@wunderkind.co",
	"FromName": `Test Email ${DEFAULT_JOB_INDEX}`,
	"Body": `<html><body><p>test ${DEFAULT_JOB_INDEX}</p></body></html>`,
	"Subject": `the subject ${DEFAULT_JOB_INDEX}`,
	"EspExtraFields": {},
};
const DEFAULT_EMAIL_JOBS: BatchSendEmailJob[] = [
	DEFAULT_EMAIL_JOB,
];

export const DEFAULT_ESP_CREDS = "";

const BatchSendEmail = ({
	defaultEmailJobs = JSON.stringify(DEFAULT_EMAIL_JOBS, null, 2),
}: BatchSendEmailProps) => {
	const dispatch = useDispatch();

	const {
		websiteid: websiteidFromSearch,
		websitename: websitenameFromSearch,
		email: emailFromSearch,
	} = useSelector(getParsedSearchState);
	const [emailArrayToString, setEmailArrayToString] = useState(emailFromSearch);
	const [emailJobsArrayToString, setEmailJobsArrayToString] = useState(defaultEmailJobs);

	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm<BatchSendEmailFormData>({
		mode: "onBlur",
		defaultValues: {
			websiteid: {
				label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
				value: websiteidFromSearch,
			},
			emailjobs: defaultEmailJobs,
			overrideEspCredsId: DEFAULT_ESP_CREDS,
		},
	});

	const getEmails = () => emailArrayToString.split(",").filter((e) => e != "");
	const [batchSendEmail] = useLazyBatchSendEmailQuery();

	const onSubmit = async ({
		websiteid,
		emailjobs,
		overrideEspCredsId,
	}: BatchSendEmailFormData) => {
		const batchSendEmailData: BatchSendEmailRequest = {
			websiteid: websiteid.value,
			emailjobs,
		};

		if (overrideEspCredsId) {
			batchSendEmailData.overrideEspCredsId = overrideEspCredsId;
		}

		try {
			const { isSuccess } = await batchSendEmail(batchSendEmailData);

			if (isSuccess) {
				dispatch(appendNotification({
					id: 0,
					text: "Emails successfully sent",
					variant: "Validation",
					hasTimeout: false,
				}) as unknown as AnyAction);
			}
		} catch {
			return catchErrorReturnString();
		}

	};

	useEffect(() => {
		if (emailJobsArrayToString !== defaultEmailJobs) {
			setValue("emailjobs", emailJobsArrayToString);
		}
	}, [defaultEmailJobs, emailJobsArrayToString, setValue]);

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Batch Send Email",
				body:
					<Body maxWidth={false}>
						<div className="send-email-wrapper">
							<form onSubmit={handleSubmit(onSubmit)}>
								<Fields>
									<Row>
										<Column>
											<WebsitePicker
												control={control}
												error={errors.websiteid}
												label="Website ID / Name"
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange } }) => (
													<Input
														name="email"
														label="Email"
														placeholder="Enter email address(es) separated by comma.."
														register={register}
														error={errors.email}
														id="emails"
														dataQA="email-input"
														isTagsInput={true}
														value={getEmails()}
														validationString="Press comma or Enter after each email."
														// using state instead of updating react-hook-form, as the value reflects the string/text input and not the tag values
														onChange={({ tags }) => {
															if (tags && tags.length > 0) {
																const tagsString = tags.length > 0 ? tags.join(",") : "";
																let emailJobsString = "";
																// update email jobs so they are all different and include each recipient:
																let emailJobsArray = new Array<BatchSendEmailJob>(tags.length)
																	.fill(DEFAULT_EMAIL_JOB)
																	.map((x, i) => ({
																		"Email": x.Email.replace("YOUR.NAME@wunderkind.co", tags[i]),
																		"From": x.From,
																		"FromName": x.FromName.replace("1", (i + 1).toString()),
																		"Body": x.Body.replace("1", (i + 1).toString()),
																		"Subject": x.Subject.replace("1", (i + 1).toString()),
																		"EspExtraFields": x.EspExtraFields,
																	}));
																emailJobsString = JSON.stringify(emailJobsArray, null, 2);
																onChange(
																	setEmailArrayToString(tagsString),
																	setEmailJobsArrayToString(emailJobsString),
																);
															}
														}}
													/>
												)}
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<div>
												<div className="label-wrapper">
													<label
														className="text-input_label text-input_label__type-text"
														htmlFor="email-jobs"
													>
														Email Jobs
													</label>
													{watch("emailjobs") !== defaultEmailJobs && (
														<LinkButton
															buttonText="Reset"
															dataQA="reset-email-jobs"
															onClick={() => {
																setEmailJobsArrayToString(JSON.stringify(defaultEmailJobs, null, 2));
																setValue("emailjobs", defaultEmailJobs);
															}}
														/>
													)}
												</div>
												<Controller
													name="emailjobs"
													control={control}
													render={({ field }) => (
														<Editor
															{...field}
															height="200px"
															id="email-jobs"
															dataQA="email-jobs-editor"
															onChange={(value) => {
																setValue("emailjobs", value);
															}}
														/>
													)}
												/>
											</div>
										</Column>
									</Row>
									<Row>
										<Column>
											<div>
												<div className="label-wrapper">
													<label
														className="text-input_label text-input_label__type-text"
														htmlFor="overrideESPCreds"
													>
														Override ESP Creds (applies to all emails in the batch)
													</label>
													{watch("overrideEspCredsId") !== DEFAULT_ESP_CREDS && (
														<LinkButton
															buttonText="Reset"
															dataQA="reset-esp-creds"
															onClick={() => setValue("overrideEspCredsId", DEFAULT_ESP_CREDS)}
														/>
													)}
												</div>
												<Controller
													name="overrideEspCredsId"
													control={control}
													render={({ field }) => (
														<Editor
															{...field}
															height="200px"
															id="overrideESPCreds"
															dataQA="override-esp-creds-editor"
															onChange={(value) => setValue("overrideEspCredsId", value)}
														/>
													)}
												/>
											</div>
										</Column>
									</Row>
									<Row>
										<Button
											buttonText="Submit"
											variant="primary"
											dataQA="submit"
											type="submit"
										/>
									</Row>
								</Fields>
							</form>
						</div>
					</Body>,
			}}
		>
		</Navigation>

	);
};
export default BatchSendEmail;
