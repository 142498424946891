import React from "react";
import {
	FieldError,
	FieldValues,
	Path,
	RegisterOptions,
	UseFormRegister,
} from "react-hook-form";
import styled from "styled-components";

import { Input as ComponentsInput, InputProps as ComponentInputProps } from "@frontend/wknd-components";

import "./Input.scss";

export type FieldInputProps<TFormValues extends FieldValues> = {
	name: Path<TFormValues>;
	register: UseFormRegister<TFormValues>;
	validators?: RegisterOptions<TFormValues>;
	error?: FieldError;
	validationString?: string;
};


export type InputProps<T extends FieldValues> = FieldInputProps<T> & Omit<ComponentInputProps, "name">;

const StyledInput = styled(ComponentsInput)`
	& input {
		padding: 0.0625rem 0.125rem;
		height: auto;
	}
`;

const Input = <T extends FieldValues>({
	label, name, register, validators, error, type = "text", onChange, validationString = "", ...props
}: InputProps<T>) => {
	let requirements = error?.message ? [error.message] : [validationString];

	return (
		<StyledInput
			type={type}
			label={label}
			aria-label={label}
			{...register(name, validators)}
			onChange={onChange}
			{...props}
			className="text-input_input text-input_input__type-text"
			validation={error && "invalid"}
			requirements={requirements}
			autoComplete="on"
		/>
	);
};

export default Input;
