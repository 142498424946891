import React from "react";

import { StyledDatePickerInput } from "./CustomDatePickerInput";

type CustomTimeInputProps = {
	value?: string;
	onChange?: (value?: string) => void;
	placeholder?: string;
};
/**
 * @see https://reactdatepicker.com/#example-custom-time-input
 */
const CustomTimeInput = ({ value, onChange }: CustomTimeInputProps) => (
	<StyledDatePickerInput
		dataQA="custom-time-picker-input"
		id="custom-time-picker-input"
		type="time"
		value={value}
		onChange={({ event }) => event?.target && onChange && onChange(event.target.value)}
	/>
);

export default CustomTimeInput;
