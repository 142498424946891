import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TriggerAttempt, TriggerEventsState } from "./types";

export enum PossibleTriggerEvents {
	ADDTOCART = "Add to Cart",
	VIEWITEM = "View Item",
	VIEWCATEGORY = "View Category",
}

const initialState: TriggerEventsState = {
	event: {
		label: "",
		value: "",
	},
	itemIds: [],
	isCustomer: false,
	endVisit: false,
	triggerAttempts: {},
};

const triggerEventsSlice = createSlice({
	name: "triggerEvents",
	initialState,
	reducers: {
		onFormSubmit: (state, action: PayloadAction<Omit<TriggerEventsState, "triggerAttempts">>) => ({
			...action.payload, triggerAttempts: state.triggerAttempts,
		}),
		addTriggerAttempt: (state, action: PayloadAction<TriggerAttempt>) => {
			const { payload } = action;
			state.triggerAttempts[payload.websiteid] //eslint-disable-line @typescript-eslint/no-unused-expressions
				? state.triggerAttempts[payload.websiteid].push(payload)
				: state.triggerAttempts[payload.websiteid] = [payload];
		},
		removeTriggerAttempt: (state, action: PayloadAction<string>) => {
			const { payload } = action;

			delete state.triggerAttempts[payload];
		},
	},
});

export const {
	onFormSubmit,
	addTriggerAttempt,
	removeTriggerAttempt,
} = triggerEventsSlice.actions;

export default triggerEventsSlice.reducer;
