import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalHeading,
	Typography,
} from "@frontend/wknd-components";

import { RootState } from "../app/store";
import { Combobox } from "../form";

import { getDefaultValues, getOptionsHolder } from "./filterValues";
import { advancedFilterTypesMap, getFilters } from "./search.selector";
import {
	applyFilters, FilterData, FilterValue, setFilters,
} from "./search.slice";
import { Event } from "./types";

const advancedFilterTypes = Array.from(advancedFilterTypesMap.values());

interface AdvancedFiltersProps {
	events: Event[],
	isOpen: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedFilters = ({
	events, isOpen, setOpen,
}: AdvancedFiltersProps) => {
	const filters = useSelector((state: RootState) => getFilters(state));
	const dispatch = useDispatch();
	const [URLParams] = useSearchParams();
	const navigate = useNavigate();
	const [defaultValues, setDefaultValues] = useState(getDefaultValues(advancedFilterTypes, filters));

	const optionsHolder = getOptionsHolder(advancedFilterTypes, events);

	const {
		control,
		handleSubmit,
		reset,
	} = useForm<FilterData>({ defaultValues });

	useEffect(() => {
		if (!filters) {
			reset();
		}
	}, [filters, reset]);

	useEffect(() => {
		if (filters) {
			let passedFilters = filters ?? {};
			setDefaultValues(passedFilters);
			reset(passedFilters);
		}
	}, [filters, reset]);

	const buildAndUpdateAdvancedURLFilterParams = (data: FilterData) => {
		advancedFilterTypesMap.forEach((dataParam, param) => {
			URLParams.delete(param);
			const filterFromFilterData = data[dataParam] as FilterValue;
			const filterFromFilterDataAsString = filterFromFilterData && filterFromFilterData.value?.toString();
			if (filterFromFilterDataAsString) {
				URLParams.set(param, filterFromFilterDataAsString);
			}
		});
		const stringifiedURLparams = createSearchParams(URLParams).toString();
		navigate({ pathname: "/results", search: stringifiedURLparams });
	};

	const onSubmit = (data: FilterData) => {
		buildAndUpdateAdvancedURLFilterParams(data);
		setOpen(false);
		dispatch(setFilters(data));
		dispatch(applyFilters());
	};

	return (
		<Modal
			isOpen={isOpen}
			contentLabel="Advanced Filters"
			dataQA="advanced-filters-modal"
			size="small"
			onRequestClose={() => setOpen(false)}
		>
			<ModalHeader dataQA="advanced-filters-modal-header">
				<ModalHeading
					component="h1"
					dataQA="advanced-filters-modal-heading"
					headingIcon="Filter"
				>
					Advanced Filters
				</ModalHeading>
			</ModalHeader>
			<ModalBody dataQA="advanced-filters-modal-body">
				<Typography dataQA="advanced-filters-subtitle" variant="bodyCopy" component="p" m="0 0 20px 0">
					Filter by device ID, visit ID, item ID, or event ID
				</Typography>
				<Combobox
					dataQA="device-id-picker-select"
					label="Device ID"
					placeholder="Filter by device ID"
					mb="8px"
					isClearable
					name="deviceid"
					control={control}
					options={optionsHolder.deviceid}
				/>
				<Combobox
					dataQA="visit-id-picker-select"
					label="Visit ID"
					placeholder="Filter by visit ID"
					mb="8px"
					isClearable
					name="visitid"
					control={control}
					options={optionsHolder.visitid}
				/>
				<Combobox
					dataQA="item-id-picker-select"
					label="Item ID"
					placeholder="Filter by item ID"
					mb="8px"
					isClearable
					name="item:id"
					control={control}
					options={optionsHolder["item:id"]}
				/>
				<Combobox
					dataQA="event-id-picker-select"
					label="Event ID"
					placeholder="Filter by event ID"
					mb="8px"
					isClearable
					name="eventid"
					control={control}
					options={optionsHolder.eventid}
				/>
			</ModalBody>
			<ModalFooter
				dataQA="advanced-filters-modal-footer"
				primaryButtonOnClick={handleSubmit(onSubmit)}
				primaryButtonText="Apply Filters"
				secondaryButtonOnClick={() => setOpen(false)}
				secondaryButtonText="Close"
			/>
		</Modal>
	);
};

export default AdvancedFilters;
