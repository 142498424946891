import React from "react";
import { useSelector } from "react-redux";

import { List } from "@frontend/thorium232/dist/list";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import { Button } from "@frontend/wknd-components";
import { nanoid } from "@reduxjs/toolkit";

import { TIMEZONE_LABEL } from "../app/constants";
import { useAppDispatch } from "../app/hooks";
import formatResultTimestamp from "../date-picker/formatResultTimestamp";
import {
	ListColumn, ListColumnHeader, ListHeader, ListRow,
} from "../list";

import { getTriggerEventsState } from "./triggerEvents.selector";
import { removeTriggerAttempt } from "./triggerEvents.slice";
import { TriggerAttempt } from "./types";

/**
 * Copy and sort attempts
 *
 * @param attempts
 * @returns
 */
const sortAttempts = (attempts: TriggerAttempt[]) => {
	const sortedAttempts = [...attempts];

	return sortedAttempts.sort(({ timestamp: a }, { timestamp: b }) => {
		const formattedA = +a;
		const formattedB = +b;

		return formattedB - formattedA;
	});
};

const TriggerEventsResults = () => {
	const { triggerAttempts } = useSelector(getTriggerEventsState);
	const dispatch = useAppDispatch();

	const onClear = (websiteid: string) => {
		dispatch(removeTriggerAttempt(websiteid));
	};

	const results = Object.entries(triggerAttempts);

	return (
		<div className="trigger-events-results">{results.map(([websiteid, attempts]) => (
			<div className="trigger-events-result" key={websiteid}>
				<div className="trigger-events-clear">
					<h2>
						{websiteid}
					</h2>
					<Button
						buttonText="Clear All"
						variant="primary"
						dataQA={`${websiteid}-clear-button`}
						onClick={() => {
							onClear(websiteid);
						}}
					/>
				</div>
				<List>
					<ListHeader key="header">
						<ListColumn size={SizeOptions.FILL}>
							<ListColumnHeader>
								Email Address
							</ListColumnHeader>
						</ListColumn>
						<ListColumn size={SizeOptions.R}>
							<ListColumnHeader>
								Email Hash
							</ListColumnHeader>
						</ListColumn>
						<ListColumn size={SizeOptions.FILL}>
							<ListColumnHeader>
								Device ID
							</ListColumnHeader>
						</ListColumn>
						<ListColumn size={SizeOptions.FILL}>
							<ListColumnHeader>
								Visit ID
							</ListColumnHeader>
						</ListColumn>
						<ListColumn size={SizeOptions.FILL}>
							<ListColumnHeader>
								Timestamp ({TIMEZONE_LABEL})
							</ListColumnHeader>
						</ListColumn>
					</ListHeader>
					{sortAttempts(attempts).map(({
						email,
						emailhash,
						deviceid,
						visitid,
						timestamp,
					}) => (
						<ListRow key={nanoid()}>
							<ListColumn size={SizeOptions.FILL}>
								{email}
							</ListColumn>
							<ListColumn size={SizeOptions.R}>
								{emailhash}
							</ListColumn>
							<ListColumn size={SizeOptions.FILL}>
								{deviceid}
							</ListColumn>
							<ListColumn size={SizeOptions.FILL}>
								{visitid}
							</ListColumn>
							<ListColumn size={SizeOptions.FILL}>
								{formatResultTimestamp(timestamp)}
							</ListColumn>
						</ListRow>
					))}
				</List>
			</div>
		))}
		</div>
	);
};

export default TriggerEventsResults;
