import { NS_IN_1MS } from "../app/constants";

import { PossibleTriggerEvents } from "./triggerEvents.slice";
import { FormData } from "./types";

declare global {
	interface Window {
		bouncex: {
			push: ([string, metadata]: [string, unknown]) => void,
			cookie: Record<string, unknown>,
			website: {
				id: number;
			}
			local_storage_frame?: HTMLIFrameElement,
		},
		setBounceCookie: () => void,
		getBounceCookie: () => {
			vid: number;
			did: string;
		},
	}
}

type PushEventsParameters = Omit<FormData, "websiteid"> & { websiteid: string };

export default async ({
	websiteid,
	email,
	endVisit,
	isCustomer,
	event: {
		label,
	},
	itemIds: itemIdsAsString,
}: PushEventsParameters) => {
	const eventMap: Record<string, string> = {
		[PossibleTriggerEvents.VIEWITEM]: "view item",
		[PossibleTriggerEvents.ADDTOCART]: "add to cart",
		[PossibleTriggerEvents.VIEWCATEGORY]: "view category",
	};

	const { bouncex } = window;

	const itemIds = itemIdsAsString.split(",");

	const theEvent: string = eventMap[label];

	const oldWebsiteId = bouncex.website.id;
	bouncex.website.id = +websiteid;

	if (endVisit) {
		bouncex.push(["visit", {}]);
	}
	if (isCustomer) {
		bouncex.push(["visit", { "customer:hasconverted": 1 }]);
	}

	bouncex.push(["user", { source: "uhd_trigger_event", email }]);

	if (label === PossibleTriggerEvents.VIEWCATEGORY) {
		bouncex.push([theEvent, { "items:ids": itemIds }]);
	} else {
		for (let i = 0; i < itemIds.length; i += 1) {
			bouncex.push([theEvent, { "item:id": itemIds[i] }]);
		}
	}

	if (endVisit) {
		await new Promise((resolve) => {
			setTimeout(resolve, 1000);
		});

		bouncex.push(["endvisit", {}]);
	}

	bouncex.website.id = oldWebsiteId;

	const { vid: visitid, did: deviceid } = window.getBounceCookie();

	return {
		visitid,
		deviceid,
		timestamp: (Date.now() * NS_IN_1MS).toString(),
	};
};
