import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Row } from "@frontend/thorium232/dist/fieldset";
import { Masthead } from "@frontend/thorium232/dist/navigation";
import { makeTabs, TabMakerConfig } from "@frontend/thorium232/dist/navigation/makeTabs";
import { colors, LogoMark, Typography } from "@frontend/wknd-components";

import tabConfig from "./tabConfig.json";

import "./Tabs.scss";

const { left: leftConfig, right: rightConfig }: { right: TabMakerConfig, left: TabMakerConfig } = tabConfig;

interface TabProps {
	/**
	 * Matches Thorium232, just pass array of tabs
	 */
	tabs?: {
		left: TabMakerConfig,
		right?: TabMakerConfig
	}
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
const LogoMarkWrapper = styled(LogoMark)`
	width: 1.8rem;
	margin-right: 10px;
`;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
const TypographerWrapper = styled(Typography)`
	margin-right: 10px;
	width: 160px;
`;

const Logo = () => (
	<Link to="/">
		<Row>
			<LogoMarkWrapper dataQA="uhd-logo-mark" fill={colors.white} width="100%" />
			<TypographerWrapper className="logo-text" dataQA="uhd-app-title" color={colors.white} variant="headlineSmall">
				User History Debugger
			</TypographerWrapper>

		</Row>
	</Link>
);

const Tabs = ({ tabs: { left } = { left: leftConfig, right: rightConfig } }: TabProps) => {
	const { pathname } = useLocation();
	const pathArr = pathname.split("/").filter(Boolean);
	const [activeTab = "", activeSubtab = ""] = pathArr;
	const [active, setActive] = useState({ activeTab, activeSubtab });

	// Set Active tab when pathname changes: so the browser back/forward buttons
	// highlight the proper tab
	useEffect(()=> {
		setActive({ activeTab, activeSubtab });
	}, [activeSubtab, activeTab, pathname]);

	const leftTabs = makeTabs({
		active,
		setActive,
		config: left,
	});

	// When on search results page (URL is /results), we want to highlight the search tab
	if (activeTab === "results" && active.activeTab !== "search") {
		// set active tab correctly when on the results page
		setActive({
			activeTab: "search",
			activeSubtab: "",
		});
	}

	// When on home page (URL is /) we want to highlight the search tab
	if (activeTab === "" && active.activeTab !== "search") {
		setActive({ activeTab: "search", activeSubtab: "" });
	}

	return (
		<Masthead
			logo={Logo}
			navItemsLeft={leftTabs}
		/>
	);
};

export default Tabs;
