import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { AlertBanner, Button, Typography } from "@frontend/wknd-components";
import { nanoid } from "@reduxjs/toolkit";

import { useLazyGetCustomerStateCheckQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import Input from "../form/Input";
import { Navigation } from "../navigation";
import { getParsedSearchState } from "../search/search.selector";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";


type CustomStateCheckFormData = {
	websiteid: {
		value: string
		label: string
	},
	email: string
};

type AlertBannerData = {
	email: string,
	isCustomer: boolean,
	id: string,
}[];

// TODO: remove once there is no dependency on Thorium
// needed so that the base.scss styles don't get applied and hide the icon
const StyledAlertBanner = styled(AlertBanner)`
	box-sizing: initial;
`;

const CustomStateCheckWrapper = styled.div`
	width: 40rem;
`;

const CustomerStateCheck = () => {
	const [alertBanners, setAlertBanners] = useState<AlertBannerData>([]);
	const {
		websiteid: websiteidFromSearch,
		websitename: websitenameFromSearch,
		email: emailFromSearch,
	} = useSelector(getParsedSearchState);
	const [emailArrayToString, setemailArrayToString] = useState(emailFromSearch);
	const [customerStateCheck] = useLazyGetCustomerStateCheckQuery();

	const defaultValues = {
		websiteid: {
			label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
			value: websiteidFromSearch,
		},
		email: "",
	};

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues,
	});

	const getEmails = () => emailArrayToString.split(",").filter((e) => e != "");


	const onSubmit = async (formData: CustomStateCheckFormData) => {
		const alertBannerData: AlertBannerData = [];

		await Promise.all(getEmails().map(async (email) => {
			try {
				const { data, isSuccess } = await customerStateCheck({ websiteid: formData.websiteid.value, email });

				if (isSuccess) {
					alertBannerData.push({ email, isCustomer: data.isCustomer, id: nanoid() });
				}
			} catch {
				return catchErrorReturnString();
			}

		}));
		setAlertBanners(alertBannerData);
	};

	const removeBanner = (id: string) => {
		const alertBannersCopy = [...alertBanners];

		const alertBannerIndex = alertBannersCopy.findIndex((banner) => banner.id === id);

		if (alertBannerIndex > -1) {
			alertBannersCopy.splice(alertBannerIndex, 1);
			setAlertBanners(alertBannersCopy);
		}
	};

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Customer State Check",
				body:
					<Body maxWidth={false}>
						<CustomStateCheckWrapper>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Fields>
									<Row>
										<Column>
											<WebsitePicker
												control={control}
												error={errors.websiteid}
												label="Website ID / Name"
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange } }) => (
													<Input
														name="email"
														label="Email"
														placeholder="Enter email address(es) separated by comma.."
														register={register}
														error={errors.email}
														id="emails"
														dataQA="email-input"
														isTagsInput={true}
														value={getEmails()}
														validationString="Press comma or Enter after each email.
												You can enter up to three emails"
														validators={{
															validate: {
																isThreeEmailsOrLess: () => {
																	if (getEmails().length > 3) {
																		return "Email input accepts a max of 3 emails separated by comma";
																	}
																},
															},
														}}
														// using state instead of updating react-hook-form, as the value reflects the string/text input and not the tag values
														onChange={({ tags }) => {
															if (tags) {
																const tagsString = tags.length > 0 ? tags.join(",") : "";
																return onChange(tags && setemailArrayToString(tagsString));
															}
														}}
													/>
												)}
											/>

										</Column>
									</Row>
									<Row>
										<Button
											buttonText="Submit"
											variant="primary"
											dataQA="submit"
											type="submit"
										/>
									</Row>
								</Fields>
							</form>
							{alertBanners.map(({ id, isCustomer, email }) => (
								<StyledAlertBanner
									key={id}
									dataQA={`${isCustomer ? "success" : "information"}-alert-banner`}
									variant={isCustomer ? "success" : "information"}
									mt="1.6rem"
									onDismiss={() => removeBanner(id)}
								>
									<Typography dataQA="alert-banner-message" variant="bodyCopy" component="p" m="0">
										<Typography
											dataQA="bolded-email"
											variant="bodyCopyBold"
											component="span"
										>
											{email}
										</Typography> is a {isCustomer ? "Customer" : "Prospect"}
									</Typography>
								</StyledAlertBanner>
							))}
						</CustomStateCheckWrapper>
					</Body>,
			}}
		>
		</Navigation>
	);
};
export default CustomerStateCheck;
