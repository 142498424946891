import React from "react";
import {
	Controller, FieldError, FieldErrorsImpl, FieldValues, Merge, UseControllerProps,
} from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import styled from "styled-components";

import { colors, Combobox as WkndCombobox, ComboboxProps as WkndComboboxProps } from "@frontend/wknd-components";

export interface OptionType {
	value: string | null;
	label: string | null;
}
export interface ComboboxProps<T> extends UseControllerProps<T & FieldValues>,
Omit<WkndComboboxProps<OptionType, boolean>, "defaultValue" | "name"> {
	options: OptionType[];
	label: string;
	error?: Merge<FieldError, FieldErrorsImpl<{ label: string; value: string; }>>;
	required?: true,
	errorMessage?: string;
	validationRule?: ({ label, value }: { label: string, value: string }) => boolean | string;
	dataQA: string
}

/**
 * We either want to show an error message or accept a custom validation function
 */
const getValidateProp = ({
	required,
	validationRule,
	errorMessage,
}: {
	required?: true,
	validationRule?: ({ label, value }: { label: string, value: string }) => boolean | string;
	errorMessage: string;
}) => {
	if (!required) {
		return undefined;
	}

	const custom = !validationRule
	 ? ({ label, value }: { label: string, value: string }) => (label !== "" && value !== "") || errorMessage
	 : validationRule;

	return {
		validate: {
			custom,
		},
	};
};

const StyledWkndComboBox = styled(WkndCombobox)<WkndComboboxProps & FieldValues>`
	color: ${colors.defaultGray};

	&:hover {
		color: inherit;
	}
`;

const Combobox = <T extends FieldValues >({
	options,
	control,
	label,
	name,
	error,
	required,
	errorMessage = "Please input a value",
	validationRule,
	dataQA,
	...props
}: ComboboxProps<T>) => (
		<Controller
			rules={getValidateProp({
				required, validationRule, errorMessage,
			})}
			name={name}
			control={control}
			render={({ field: { ref, value, ...rest } }) => (
				<StyledWkndComboBox
					comboboxIcon="Search"
					label={label}
					dataQA={dataQA}
					id={label}
					labelHtmlFor={label}
					aria-label={label}
					options={options}
					comboboxRef={ref}
					helperText={error?.message}
					menuPlacement="auto"
					menuPosition="fixed"
					menuPortalTarget={document.body}
					value={value?.value === "" ? null : value}
					required={required}
					styles={{ menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }) }}
					{...rest}
					{...props}
				/>

			)}
		/>
	);

export default Combobox;
