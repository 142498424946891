import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import { Button, Typography } from "@frontend/wknd-components";

import { useLazyGetEmailFromIDQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import Input from "../form/Input";
import { Navigation } from "../navigation";
import { getParsedSearchState } from "../search/search.selector";
import {
	setWebsite,
} from "../search/search.slice";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";

import "./EspIdToEmail.scss";

interface EspIdForm {
	espid: string;
	websiteid: {
		label: string;
		value: string;
	};
}

const EspIDToEmail = () => {
	const [email, setEmail] = useState("");
	const dispatch = useDispatch();
	const [getEmailFromId] = useLazyGetEmailFromIDQuery();

	const {
		websiteid: websiteidFromSearch, websitename: websitenameFromSearch,
	} = useSelector(getParsedSearchState);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			espid: "",
			websiteid: {
				label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
				value: websiteidFromSearch,
			},
		},
	});

	const espIdRequest = async (espid: string, websiteid: string): Promise<string> => {
		try {
			const res = await getEmailFromId({ espid, websiteid }).unwrap();
			if (!res.success || !("email" in res)) {
				return "";
			}
			return res.email;
		} catch {
			return catchErrorReturnString();
		}
	};

	const submitLookUp = async (data: EspIdForm) => {
		const { espid, websiteid } = data;

		const response = await espIdRequest(espid, websiteid.value);

		setEmail(response);
		dispatch(setWebsite({
			id: data.websiteid.value,
			name: data.websiteid.label,
		}));
	};


	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | ESP ID to Email",
				body:
					<Body maxWidth={false}>
						<div className="wrapper">
							<Fields>
								<Row>
									<Column>
										<WebsitePicker
											control={control}
											error={errors.websiteid}
											label="Website ID / Name"
										/>
									</Column>
								</Row>

								<Row>
									<Column>
										<Input
											name="espid"
											label="ESP ID"
											placeholder="Enter Users ESP ID here"
											register={register}
											id="espid"
											dataQA="espid-input"
											required
										/>
									</Column>
								</Row>

								<Row>
									<Column size={SizeOptions.S}>
										<Button
											variant="primary"
											buttonText="Search"
											onClick={handleSubmit(submitLookUp)}
											dataQA="search-button"
											mt="20px"
										/>
									</Column>
								</Row>
								{email && (
									<Row>
										<Column>
											<Typography dataQA="" variant="bodyCopyBold">
												Email:
											</Typography>
										</Column>
										<Column>
											<Typography dataQA="" variant="bodyCopySmall">
												{email}
											</Typography>
										</Column>
									</Row>
								)}
							</Fields>
						</div>
					</Body>,
			}}
		/>


	);
};

export default EspIDToEmail;
