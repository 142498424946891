import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Body } from "@frontend/thorium232/dist/layout";
import { Button } from "@frontend/wknd-components";

import { useLazyGetDevicesFromPrivacyIdQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import { Combobox, Input } from "../form/";
import { Navigation } from "../navigation";
import { getSearchState } from "../search/search.selector";
import {
	setWebsite,
} from "../search/search.slice";
import { validateEmail, validateNumber } from "../search/searchTypes";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";

import { getPrivacyState } from "./privacy.selector";
import { PrivacySearchTypes } from "./privacy.slice";
import {
	clearPrivacy,
	setDeviceIdResults,
	setInput,
	setPrivacyIdResults,
	setResults,
	setSearchType,
} from "./privacy.slice";
import { DivSearchFields, SearchButtonWrapper, StyledCard, StyledRow } from "./PrivacyIdLookUp.styles";
import PrivacyIdLookUpResults from "./PrivacyIdResults";
import { BasePrivacyIdRequest, DevicesFromPrivacyId } from "./types";

interface PrivacyIdForm {
	input: string;
	searchType: { label: string, value: PrivacySearchTypes };
	websiteid: {
		label: string;
		value: string;
	};
}

const searchTypeOptions = [
	{ value: PrivacySearchTypes.EMAIL, label: "Email" },
	{ value: PrivacySearchTypes.PHONE, label: "Phone" },
	{ value: PrivacySearchTypes.EMAIL_HASH, label: "Email Hash" },
	{ value: PrivacySearchTypes.PHONE_HASH, label: "Phone Hash" },
	{ value: PrivacySearchTypes.DEVICE_ID, label: "Device ID" },
	{ value: PrivacySearchTypes.SOFT_ID, label: "Soft ID" },
	{ value: PrivacySearchTypes.DEVICE_TOKEN, label: "Device Token" },
	{ value: PrivacySearchTypes.PRIVACY_ID, label: "Privacy ID" },
	{ value: PrivacySearchTypes.KLAVIYO_ID, label: "Klaviyo ID" },
	{ value: PrivacySearchTypes.ATTENTIVE_ID, label: "Attentive ID" },
	{ value: PrivacySearchTypes.BLUE_CONIC_ID, label: "BlueConic ID" },
	{ value: PrivacySearchTypes.LOYALTY_PLUS_ID, label: "Loyalty Plus ID" },
	{ value: PrivacySearchTypes.CUSTOMER_ID, label: "Customer ID" },
];


const PrivacyIdLookUp = () => {
	const dispatch = useDispatch();
	const {
		websiteid: searchStateWebsiteId = "",
		websitename: searchStateWebsiteName = "",
	} = useSelector(getSearchState);
	const {
		results,
		privacyIds,
		deviceIds,
		input,
		searchType: searchStateType,
	} = useSelector(getPrivacyState);
	const [getDeviceFromPrivacyId] = useLazyGetDevicesFromPrivacyIdQuery();

	const {
		register,
		control,
		watch,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "all",
		defaultValues: {
			input: input,
			searchType: searchTypeOptions.find((searchTypeOption) =>
				searchTypeOption.value === searchStateType) || searchTypeOptions[0],
			websiteid: {
				value: searchStateWebsiteId,
				label: formatWebsiteLabel({ label: searchStateWebsiteName, value: searchStateWebsiteId }),
			},
		},
	});

	const currentType = watch("searchType");
	const getInputPlaceholder = (searchType: PrivacySearchTypes): string => {
		switch (searchType) {
			case PrivacySearchTypes.EMAIL:
				return "User Email...";
			case PrivacySearchTypes.PHONE:
				return "User Phone...";
			case PrivacySearchTypes.EMAIL_HASH:
				return "User Email Hash...";
			case PrivacySearchTypes.PHONE_HASH:
				return "User Phone Hash...";
			case PrivacySearchTypes.DEVICE_ID:
				return "User Device ID...";
			case PrivacySearchTypes.SOFT_ID:
				return "User Soft ID...";
			case PrivacySearchTypes.DEVICE_TOKEN:
				return "User Device Token...";
			case PrivacySearchTypes.PRIVACY_ID:
				return "User Privacy ID...";
			case PrivacySearchTypes.KLAVIYO_ID:
				return "User Klaviyo ID...";
			case PrivacySearchTypes.ATTENTIVE_ID:
				return "User Attentive ID...";
			case PrivacySearchTypes.BLUE_CONIC_ID:
				return "User BlueConic ID...";
			case PrivacySearchTypes.LOYALTY_PLUS_ID:
				return "User Loyalty Plus ID...";
			case PrivacySearchTypes.CUSTOMER_ID:
				return "User Customer ID...";
			default:
				return "User Email, Hash, Phone, or other ID...";
		}
	};

	const filterSortAndSetRecords = (records: DevicesFromPrivacyId[]) => {
		let filterOutNull = records.filter((result) => result != null && result?.id);
		dispatch(setPrivacyIdResults(filterOutNull.filter((result) => result.type === 6)));
		dispatch(setDeviceIdResults(filterOutNull.filter((result) => result.type === 1)));
		dispatch(setResults(filterOutNull.filter((record) => record.type !== 6 && record.type !== 1)));
		return;
	};

	const updateResultsToRemoveRecord = (deviceId: string) => {
		if (deviceIds) {
			const newResults = deviceIds.filter((result) => result.id !== deviceId);
			dispatch(setDeviceIdResults(newResults));
		}
	};

	const submitLookUp = async (formData: PrivacyIdForm) => {
		dispatch(clearPrivacy());

		try {
			const { searchType, websiteid } = formData;
			dispatch(setInput(formData.input));
			dispatch(setSearchType(searchType.value));
			dispatch(setWebsite({
				id: websiteid.value,
				name: websiteid.label,
			}));

			let payload: BasePrivacyIdRequest = { websiteid: websiteid.value };
			if (searchType.value === PrivacySearchTypes.EMAIL) {
				payload.email = formData.input;
			} else if (searchType.value === PrivacySearchTypes.PHONE) {
				payload.phone = formData.input;
			} else if (searchType.value === PrivacySearchTypes.EMAIL_HASH) {
				payload.emailhash = formData.input;
			} else if (searchType.value === PrivacySearchTypes.PHONE_HASH) {
				payload.phonehash = formData.input;
			} else if (searchType.value === PrivacySearchTypes.DEVICE_ID) {
				payload.deviceid = formData.input;
			}  else if (searchType.value === PrivacySearchTypes.SOFT_ID) {
				payload.softid = formData.input;
			}  else if (searchType.value === PrivacySearchTypes.DEVICE_TOKEN) {
				payload.devicetoken = formData.input;
			}  else if (searchType.value === PrivacySearchTypes.PRIVACY_ID) {
				payload.privacyid = formData.input;
			} else if (searchType.value === PrivacySearchTypes.KLAVIYO_ID) {
				payload.klaviyoid = formData.input;
			} else if (searchType.value === PrivacySearchTypes.ATTENTIVE_ID) {
				payload.attentiveid = formData.input;
			} else if (searchType.value === PrivacySearchTypes.BLUE_CONIC_ID) {
				payload.blueconicid = formData.input;
			} else if (searchType.value === PrivacySearchTypes.LOYALTY_PLUS_ID) {
				payload.loyaltyplusid = formData.input;
			} else if (searchType.value === PrivacySearchTypes.CUSTOMER_ID) {
				payload.customerid = formData.input;
			}

			const { data, isSuccess } = await getDeviceFromPrivacyId(payload);
			if (isSuccess) {
				filterSortAndSetRecords(data.privacyRecords);
				return;
			}
		} catch {
			dispatch(setResults([]));
			return catchErrorReturnString();
		}
	};


	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Privacy ID Look Up",
				body:
					<Body maxWidth={false}>
						<div>
							<StyledCard dataQA="search-card" ariaLabel="search-card">
								<StyledRow>
									<DivSearchFields>
										<WebsitePicker control={control} error={errors.websiteid} label="Website ID / Name" />
									</DivSearchFields>
									<DivSearchFields>
										<Input
											label="Email / Phone / Hash / Other ID"
											name="input"
											required
											placeholder={getInputPlaceholder(currentType.value)}
											dataQA="type-input"
											id="input-type"
											register={register}
											validators={{
												required: "User Input is required",
												validate: {
													isEmail: (v) => getValues("searchType").value !== PrivacySearchTypes.EMAIL
														|| validateEmail(v as string)
														|| "Invalid Email",
													isPhone: (v) => getValues("searchType").value !== PrivacySearchTypes.PHONE
														|| validateNumber(v as string)
														|| "Phone must be a number",
												},
											}}
											error={errors.input}
										/>
									</DivSearchFields>
									<DivSearchFields>
										<Combobox
											dataQA="search-type-select"
											label="SearchType"
											name="searchType"
											control={control}
											options={searchTypeOptions}
										/>
									</DivSearchFields>
									<SearchButtonWrapper>
										<Button
											variant="primary"
											buttonText="Search"
											onClick={handleSubmit(submitLookUp)}
											dataQA="search-button"
											mt="1.6rem"
										/>
									</SearchButtonWrapper>
								</StyledRow>
							</StyledCard>
							{privacyIds && (
								<PrivacyIdLookUpResults results={privacyIds} title="Privacy IDs" />
							)}
							{deviceIds && (
								// input and searchType and updateResultsToRemoveRecord are used for the Detach DeviceID button
								<PrivacyIdLookUpResults
								 results={deviceIds} title="Device IDs"
								 input={getValues("input")}
								 searchType={getValues("searchType.value")}
								 updateResultsToRemoveRecord={updateResultsToRemoveRecord}
								/>
							)}
							{results && (
								<PrivacyIdLookUpResults results={results} title="Other IDs" />
							)}
						</div>
					</Body>,
			}}
		/>


	);
};

export default PrivacyIdLookUp;
