import { emptyStrings, FilterBys, FilterData, isMultiKey, splitKeys } from "./search.slice";
import { Event } from "./types";

export const getDefaultValues = (filterTypes: FilterBys[], filters: FilterData | null): FilterData => (
	filterTypes.reduce((prev, key) => ({
		...prev,
		[key]: filters && filters[key] ? { ...filters[key] } : { label: "", value: "" },
	}), {})
);

export const getOptionsHolder = (filterTypes: FilterBys[], events: Event[]): {
	[key: string]: {
		label: string;
		value: string;
	}[]
} => {
	const optionsHolder: {
		[key: string]: {
			label: string;
			value: string;
		}[]
	} = filterTypes.reduce((prev, key) => ({
		...prev,
		[key]: [],
	}), {});

	// Easy way to avoid duplicates
	let tempOptionsHolder: Record<string, Record<string, boolean>> = {};

	Object.entries(optionsHolder).forEach(([key, values]) => {
		if (isMultiKey(key as FilterBys)) {
			tempOptionsHolder[key] = {};
		}
		events.forEach((event) => {
			if (isMultiKey(key as FilterBys)) {
				const keysWithValue = splitKeys(key as FilterBys).filter((splittedKey) => !!event[splittedKey]);

				if (keysWithValue.length === 0) {
					tempOptionsHolder[key][emptyStrings[key]] = true;
					return;
				}

				keysWithValue.forEach((keyWithValue) => tempOptionsHolder[key][event[keyWithValue]] = true);
				return;
			}


			if (values.find(({ value }) => value === event[key] || (value === emptyStrings[key] && !event[key]))) {
				return;
			}

			optionsHolder[key].push({
				label: event[key] || emptyStrings[key],
				value: event[key] || emptyStrings[key],
			});
		});

		if (isMultiKey(key as FilterBys)) {
			Object.keys(tempOptionsHolder[key]).forEach((value) => optionsHolder[key].push({
				label: value,
				value: value,
			}));
		}

		optionsHolder[key].sort((a, b) => a.label > b.label ? 1 : -1);
	});

	return optionsHolder;
};
