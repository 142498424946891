import React from "react";

import { Layout } from "@frontend/thorium232/dist/layout";
import { LayoutProps } from "@frontend/thorium232/dist/layout/Layout";

interface NavigationProps extends React.PropsWithChildren {
	/**
	 * Copying props from Thorium232 layout component
	 */
	layout: LayoutProps
}

/**
 * Wrapper for Thorium232 Layout
 */
const Navigation = ({ children, layout }: NavigationProps) => {
	const { children: layoutChildren, ...rest } = layout;

	return (
		<Layout {...rest}>
			{layoutChildren}
			{children}
		</Layout>
	);
};

export default Navigation;
