import {
	Action,
	configureStore, ThunkAction } from "@reduxjs/toolkit";

import notifications from "../notifications/notifications.slice";
import privacyLookUp from "../privacy-id-look-up/privacy.slice";
import initialSearch from "../search/initialSearch.slice";
import search from "../search/search.slice";
import featureFlag from "../settings/featureFlag.slice";
import triggerEvents from "../trigger-events/triggerEvents.slice";

import { apiSlice } from "./api";
import { rtkQueryErrorLogger } from "./errorHandling";

export const store = configureStore({
	reducer: {
		featureFlag,
		search,
		initialSearch,
		notifications,
		[apiSlice.reducerPath]: apiSlice.reducer,
		triggerEvents,
		privacyLookUp,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware, rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
