import { SearchState } from "../search/search.slice";

type WebsiteSelection = {
	value: string;
	label: string;
};

/**
 * Translate the form label and value to websiteid and websitename for search store
 * @param website
 * @returns {{id: SearchState["websiteid"]; name: SearchState["websitename"];}}
 */
export const parseWebsiteFromForm = ({ label, value }: WebsiteSelection): {
	id: SearchState["websiteid"];
	name: SearchState["websitename"];
} => ({
	id: value,
	name: label,
});

export const formatWebsiteLabel = ({ label, value }: WebsiteSelection): string => `${label} - ${value}`;
