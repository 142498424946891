import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import LocalStorage from "../localStorage";

export const OVERRIDE_STORAGE_KEY = "override_storage";

export const enum FeatureNames {
	SEARCH = "SEARCH",
	FILTERING = "FILTERING",
}

type Environments = "staging" | "production";

export type FeatureFlagOptions = "on" | "off" | Environments;

export type FeatureFlagState = { [key in FeatureNames]: FeatureFlagOptions };

const features: FeatureFlagState = {
	[FeatureNames.SEARCH]: "on",
	[FeatureNames.FILTERING]: "staging",
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const getLocalStorageData = () => LocalStorage.get(OVERRIDE_STORAGE_KEY, {});

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const initialState: FeatureFlagState = { ...features, ...getLocalStorageData() };

const featureFlagSlice = createSlice({
	name: "featureFlag",
	initialState,
	reducers: {
		setOverride: (state, action: PayloadAction<{ key: FeatureNames, value: FeatureFlagOptions }>) => {
			const { key, value } = action.payload;

			LocalStorage.set(OVERRIDE_STORAGE_KEY, {
				...state,
				[key]: value,
			});

			return {
				...state,
				[key]: value,
			};
		},
		matchEnvironment: (state, action: PayloadAction<Environments>) => {
			const environment = action.payload as FeatureFlagOptions;

			Object.keys(state).forEach((key) => {
				state[key as FeatureNames] = environment;
			});

			LocalStorage.set(OVERRIDE_STORAGE_KEY, { ...state });
		},
	},
});

export const { setOverride, matchEnvironment } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
