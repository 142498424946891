import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
	GetCustomerStateCheckResponse, GetStatusCheckBaseRequest, GetUnsubscribeCheckRequest,
	GetUnsubscribeCheckResponse,
} from "../artifacts/types";
import { BatchSendEmailRequest, BatchSendEmailResponse } from "../batch-send-email/types";
import {
	GetCampaignEligibilityRequest,
	GetCampaignEligibilityResponse,
	GetEligibilityRequest,
	GetEligibilityResponse,
	GetWebsiteEligibilityRequest,
	GetWebsiteEligibilityResponse,
	GetWebsiteESPSettingsRequest,
	GetWebsiteESPSettingsResponse,
} from "../eligibility/types";
import { GetEmailFromIdBaseRequest, GetEmailFromIdBaseResponse } from "../esp-id-to-email/types";
import {
	BasePrivacyIdRequest,
	DetachPrimaryIdRequest,
	DetachPrimaryIdResponse,
	GetDevicesFromPrivacyIdResponse,
} from "../privacy-id-look-up/types";
import {
	BaseDevicesRequest,
	BaseGetEmailEventsRequest,
	GetDeviceEventsRequest,
	GetDeviceEventsResponse,
	GetDevicesResponse,
	GetEmailEventsResponse,
} from "../search/types";
import { SendEmailRequest, SendEmailResponse } from "../send-email/types";

import { BaseRequest, GetUserResponse } from "./types";

const getURLQueryParams = (req: BaseRequest, shouldDecodeURI = false) => {
	const urlParams = new URLSearchParams(req).toString();
	return shouldDecodeURI ? decodeURI(urlParams) : urlParams;
};

export const getDevicesURL = (req: BaseDevicesRequest): string => {
	if ("phone" in req || "phonehash" in req) {
		return `/get_phone_devices?${getURLQueryParams(req)}`;
	}

	if ("email" in req || "emailhash" in req) {
		return `/get_email_devices?${getURLQueryParams(req)}`;
	}

	return "";
};
const baseApi = process.env.REACT_APP_BASE_API_URL as string;
const gatewayApi = process.env.REACT_APP_GATEWAY_URL as string;
const guiApi = process.env.REACT_APP_GUI_API_URL as string;

export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		prepareHeaders: async (headers) => {
			await window.wkndAuthentication.getToken().then((token) => {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				headers.set("Authorization", `Bearer ${token}`);
				return headers;
			});
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getWebsiteEligibility: builder.query<GetWebsiteEligibilityResponse, GetWebsiteEligibilityRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);
				return `${baseApi}/get_website_eligibilityjs?${urlQueryString}`;
			},
		}),
		getCampaignEligibility: builder.query<GetCampaignEligibilityResponse, GetCampaignEligibilityRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_campaign_eligibilityjs?${urlQueryString}`;
			},
		}),
		getWebsiteESPSettings: builder.query<GetWebsiteESPSettingsResponse, GetWebsiteESPSettingsRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);
				return `${baseApi}/get_website_espsettings?${urlQueryString}`;
			},
		}),
		getEmailFromID: builder.query<GetEmailFromIdBaseResponse, GetEmailFromIdBaseRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_esp_id?${urlQueryString}`;
			},
		}),
		getEmailEvents: builder.query<GetEmailEventsResponse, BaseGetEmailEventsRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_email_events?${urlQueryString}`;
			},
		}),
		getDeviceEvents: builder.query<GetDeviceEventsResponse, GetDeviceEventsRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_device_events?${urlQueryString}`;
			},
		}),
		getDeviceIds: builder.query<GetDevicesResponse, BaseDevicesRequest>({
			query: (req) => `${baseApi}${getDevicesURL(req)}`,
		}),
		getSetUnsubscribe: builder.query<GetUnsubscribeCheckResponse, GetUnsubscribeCheckRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_unsubscribe_check?${urlQueryString}`;
			},
		}),
		getUnsubscribeCheck: builder.query<GetUnsubscribeCheckResponse, GetUnsubscribeCheckRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_unsubscribe_check?${urlQueryString}`;
			},
		}),
		getCustomerStateCheck: builder.query<GetCustomerStateCheckResponse, GetStatusCheckBaseRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);


				return `${baseApi}/get_customer_state?${urlQueryString}`;
			},
		}),
		getWebsiteFromCoreApi: builder.query({
			query: (req: string) => ({
				url: `${gatewayApi}/websites/${req}`,
				mode: "cors",
				prepareHeaders: async (headers: Headers) => {
					await window.wkndAuthentication.getToken().then((token) => {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						headers.set("Authorization", `Bearer ${token}`);
						return headers;

					});
					return headers;
				},
			}),
		}),
		getEligibility: builder.query<GetEligibilityResponse, GetEligibilityRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req, true);
				return `${baseApi}/get_eligibility?${urlQueryString}`;
			},
		}),
		sendEmail: builder.query<SendEmailResponse, SendEmailRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/send_email?${urlQueryString}`;
			},
		}),
		batchSendEmail: builder.query<BatchSendEmailResponse, BatchSendEmailRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/batch_send_email?${urlQueryString}`;
			},
		}),
		getUserInfo: builder.query<GetUserResponse, void>({
			query: () => `${guiApi}/api/v3/user`,
		}),
		getDevicesFromPrivacyId: builder.query<GetDevicesFromPrivacyIdResponse, BasePrivacyIdRequest>({
			query: (req) => {
				const urlParams = new URLSearchParams(req).toString();

				return `${baseApi}/get_privacy_records?${urlParams}`;
			},
		}),
		detachPrimaryID: builder.mutation<DetachPrimaryIdResponse, DetachPrimaryIdRequest>({
			query: (req) => ({
				 url: `${baseApi}/detach_primary_id`,
				 method: "POST",
				 body: req,
			}),
		}),
		getPhoneEvents: builder.query<GetDeviceEventsResponse, BaseDevicesRequest>({
			query: (req) => {
				const urlQueryString = getURLQueryParams(req);

				return `${baseApi}/get_phone_events?${urlQueryString}`;
			},
		}),
	}),
});

export const {
	useLazyGetWebsiteEligibilityQuery,
	useLazyGetCampaignEligibilityQuery,
	useLazyGetWebsiteESPSettingsQuery,
	useLazyGetEmailFromIDQuery,
	useLazyGetEmailEventsQuery,
	useLazyGetDeviceEventsQuery,
	useLazyGetSetUnsubscribeQuery,
	useGetDeviceIdsQuery,
	useGetUnsubscribeCheckQuery,
	useGetCustomerStateCheckQuery,
	useLazyGetCustomerStateCheckQuery,
	useGetWebsiteFromCoreApiQuery,
	useLazyGetEligibilityQuery,
	useLazySendEmailQuery,
	useLazyBatchSendEmailQuery,
	useGetUserInfoQuery,
	useLazyGetDevicesFromPrivacyIdQuery,
	useDetachPrimaryIDMutation,
	useLazyGetPhoneEventsQuery,
} = apiSlice;
