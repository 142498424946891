import React, { PropsWithChildren } from "react";
import classnames from "classnames";

import { SizeOptions, WithDataQa } from "@frontend/thorium232/dist/types/base";

interface ListHeaderProps extends WithDataQa, PropsWithChildren {
	backgroundColor?: "gray" | "white";
	paddingSize?: SizeOptions.S | SizeOptions.R | SizeOptions.L;
	sticky?: boolean;
}

const ListHeader = ({
	children, backgroundColor, paddingSize = SizeOptions.R, sticky, dataQa = "list-header",
}: ListHeaderProps) => (
	<header
		className={classnames(
			"list-header",
			{
				list_row__padding: paddingSize,
				[`list_row__padding-${paddingSize}`]: paddingSize,
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				[`list-header__background-color-${backgroundColor}`]: backgroundColor,
				"list-header__sticky": sticky,
			},
		)}
		data-qa={dataQa}
	>
		{children}
	</header>
);

export default ListHeader;
