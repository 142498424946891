import React, { MouseEventHandler, PropsWithChildren } from "react";
import classnames from "classnames";

import { SizeOptions, WithDataQa } from "@frontend/thorium232/dist/types/base";

import "./ListRow.scss";

interface ListRowProps extends WithDataQa, PropsWithChildren {
	selected?: boolean;
	disabled?: boolean;
	paused?: boolean;
	borderBetweenCols?: boolean;
	rowStyle?: "dim" | "bold";
	paddingSize?: SizeOptions.S | SizeOptions.R | SizeOptions.L;
	align?: "top";
	backgroundType?: "warning" | "warning-expanded" | "selected" | "selected-expanded";
	onClick?: MouseEventHandler
}

const ListRow = ({
	paddingSize = SizeOptions.R,
	rowStyle,
	selected,
	disabled,
	onClick,
	children,
	borderBetweenCols,
	align,
	paused,
	backgroundType,
	dataQa = "list-row",
}: ListRowProps) => (
	<div
		onClick={onClick}
		className={classnames(
			"list_row",
			{
				list_row__clickable: onClick,
				list_row__selected: selected,
				list_row__paused: paused,
				list_row__disabled: disabled,
				"list_row__bordered-cols": borderBetweenCols,
				[`list_row__${rowStyle as string}`]: rowStyle,
				list_row__padding: paddingSize,
				[`list_row__padding-${paddingSize}`]: paddingSize,
				[`list_row__align-${align as string}`]: align,
				[`list_row__bg-${backgroundType as string}`]: backgroundType,
			},
		)}
		role="presentation"
		data-qa={dataQa}
	>
		{children}
	</div>
);

export default ListRow;
