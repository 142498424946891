/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call  */
import React from "react";
import { formatInTimeZone } from "date-fns-tz";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import {
	Badge,
	BadgeVariants,
	Divider,
	Spinner,
	Typography,
} from "@frontend/wknd-components";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import {
	useGetCustomerStateCheckQuery,
	useGetDeviceIdsQuery,
	useGetUnsubscribeCheckQuery,
	useGetWebsiteFromCoreApiQuery,
} from "../app/api";
import { NS_IN_1MS, TIMEZONE } from "../app/constants";
import { ScrollableCardWithMargin } from "../form/ScrollableCard";
import { SideBar } from "../navigation";
import { getPrivacyState } from "../privacy-id-look-up/privacy.selector";
import { getSearchState, isType } from "../search/search.selector";
import { SearchTypes } from "../search/search.slice";
import { BaseDevicesRequest } from "../search/types";

import { StyledLinkButton } from "./Artifacts.style";
import filterByEventName, { getCampaignIds } from "./filterForSubmitEvents";
import ToDeviceIdButton from "./ToDeviceIdButton";
import { GetCustomerStateCheckResponse, GetUnsubscribeCheckResponse } from "./types";

import "./Artifacts.scss";

interface StatusInformationBadge {
	text: string,
	variant: BadgeVariants
}

const customerStatusDataFormatted: StatusInformationBadge[] = [{
	text: "Prospect",
	variant: "error",
}, {
	text: "Customer",
	variant: "active",
}, {
	text: "Customer Status Not Found",
	variant: "default",
}];

const unsubscribeStatusDataFormatted: StatusInformationBadge[] = [{
	text: "Mailable",
	variant: "active",
}, {
	text: "Not Mailable",
	variant: "error",
}, {
	text: "Unsubscribe Status Not Found",
	variant: "default",
}];

const unsubscribeIBX1DataFormatted: StatusInformationBadge[] = [{
	...unsubscribeStatusDataFormatted[0],
	text: "Not Unsubscribed",
}, {
	...unsubscribeStatusDataFormatted[1],
	text: "Unsubscribed",
}, {
	...unsubscribeStatusDataFormatted[2],
}];

const Artifacts = () => {
	const {
		websiteid,
		websitename,
		type,
		input,
		result = [],
		lastActiveDevices,
	} = useSelector(getSearchState);
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const formattedType = isType(type, "device") ? "Device ID" : type.replaceAll("_", " ");

	const websiteIdFromParams = searchParams.get("websiteId");
	const searchTypeFromParams = searchParams.get("searchType");
	const searchInputFromParams = searchParams.get("searchInput");
	const {
		input: privacySearchStateInput,
		searchType: privacySearchStateType,
	} = useSelector(getPrivacyState);

	const isEmail = isType(type, "email");
	const isPhone = isType(type, "phone");
	const isEmailHash = isType(type, "email_hash");

	const isSameParams = websiteIdFromParams === websiteid
		&& searchTypeFromParams === type
		&& searchInputFromParams === input;

	const isPhoneOrEmail = isEmail || isPhone;

	const { email: submittedEmailCampaignIDs, phone: submittedPhoneCampaignIDs } = getCampaignIds({
		...filterByEventName({ events: result || [] }),
	});

	const {
		data: coreData = {
			data: {
				attributes: {},
			},
		},
		isLoading: isLoadingCoreData,
	} = useGetWebsiteFromCoreApiQuery(websiteid, {
		skip: websiteid === "" || !isSameParams,
	});

	const {
		data: {
			attributes: {
				website: url,
			},
		},
	} = coreData;

	const {
		isError: unsubIsError,
		error: unsubErrorData,
		data: unsubSuccessData,
		isLoading: isLoadingUnsubData,
	} = useGetUnsubscribeCheckQuery({
		websiteid,
		email: input,
		disable_cache: "1",
	}, {
		skip: !isEmail || input === "" || !isSameParams,
	});

	const getUnsubBadge = (
		data: GetUnsubscribeCheckResponse | FetchBaseQueryError | SerializedError | undefined,
		ibx1: boolean,
	) => {
		if (data && "IsUnsubscribed" in data && (
			data.IsUnsubscribed !== undefined || data.IsUnsubscribedIbx1 !== undefined
		)) {
			return ibx1
				? unsubscribeIBX1DataFormatted[+data.IsUnsubscribedIbx1]
				: unsubscribeStatusDataFormatted[+data.IsUnsubscribed];

		}

		return ibx1 ? unsubscribeIBX1DataFormatted[2] : unsubscribeStatusDataFormatted[2];
	};

	const {
		data: customerStateSuccessData,
		isLoading: isLoadingCustomerStateData,
		isError: customerStateIsError,
		error: customerStateErrorData,
	} = useGetCustomerStateCheckQuery({
		websiteid,
		email: input,
	}, {
		skip: !isEmail || input === "" || !isSameParams,
	});


	const getCustomerBadge = (
		data: GetCustomerStateCheckResponse | FetchBaseQueryError | SerializedError | undefined,
	) => {
		if (data && "isCustomer" in data && data.isCustomer !== undefined) {
			return customerStatusDataFormatted[+data.isCustomer];
		}

		return customerStatusDataFormatted[2];
	};

	const deviceIds: string[] = [];

	let payload: BaseDevicesRequest = { email: "", websiteid: "" };

	switch (type) {
		case SearchTypes.PHONE:
			payload = { phone: input, websiteid };
			break;
		case SearchTypes.EMAIL_HASH:
			payload = { emailhash: input, websiteid };
			break;
		case SearchTypes.EMAIL:
			payload = { email: input, websiteid };
			break;
		case SearchTypes.PHONE_HASH:
			payload = { phonehash: input, websiteid };
			break;
		default:
			break;
	}

	const {
		data: retrievedDeviceIds = {
			deviceids: [],
		},
		isLoading: isLoadingDeviceIDs,
	} = useGetDeviceIdsQuery(payload, {
		skip: !isPhoneOrEmail || !isSameParams,
	});

	if (isType(type, "device")) {
		deviceIds.push(input);
	} else {
		deviceIds.push(...retrievedDeviceIds.deviceids);
	}

	// no need to check if anything is loading if the params are the same
	// because then we know the data is already loaded
	const isLoading = () => !isSameParams
		|| isLoadingCoreData || isLoadingCustomerStateData || isLoadingDeviceIDs || isLoadingUnsubData;

	const generalInfo = () => (
		<>
			<Row>
				<Typography
					variant="bodyCopySmallBold"
					dataQA="website-ID"
					className="general-label-wrapper"
				>
					Website ID:
				</Typography>
				<Column>
					{websiteid}
				</Column>
			</Row>
			<Row>
				<Typography
					variant="bodyCopySmallBold"
					dataQA="website-name"
					className="general-label-wrapper"
				>
					Website Name:
				</Typography>
				<Column>
					{websitename}
				</Column>
			</Row>
			<Row>
				<Typography
					variant="bodyCopySmallBold"
					dataQA="website"
					className="general-label-wrapper"
				>
					Website:
				</Typography>
				<Column>
					<a
						href={url.indexOf("http") !== -1 ? url : `https://${url}`}
						target="_blank"
					>
						{url}
					</a>
				</Column>
			</Row>
			{(isEmail || isEmailHash) && input !== "" && (
				<Row>
					<div className="general-label-wrapper">
						<Typography
							variant="bodyCopySmallBold"
							dataQA="email"
							className="sidebar-email-label"
						>
							Email:
						</Typography>
					</div>
					<div title={input} className="email-text">
						{input}
					</div>
				</Row>
			)}
			<Row>
				<Typography
					variant="bodyCopySmallBold"
					dataQA="website"
					className="general-label-wrapper"
				>
					Search Type:
				</Typography>
				<Column>
					{formattedType}
				</Column>
			</Row>
		</>
	);

	const devicesInfo = () => (
		<>
			<Row>
				<Column>
					<Typography
						variant="headlineSmall"
						dataQA="user-artifacts"
						component="h3"
					>
						Devices
					</Typography>
				</Column>
			</Row>
			<div className="deviceids-list">
				<Row>
					<ul>
						{deviceIds.map((deviceId) => (
							<li key={deviceId}>
								<ToDeviceIdButton deviceId={deviceId} />
								<Typography
									dataQA="device-last-active"
									color="gray"
									mt="2px"
									ml="24px"
								>
									{
										lastActiveDevices[deviceId]
											? `Last active ${formatInTimeZone(
												new Date(lastActiveDevices[deviceId] / NS_IN_1MS),
												TIMEZONE,
												"P",
											)}`
											: "Last active not available"
									}
								</Typography>
							</li>
						))}
					</ul>
				</Row>
			</div>
		</>
	);

	const campaignInfo = () => (
		<>
			<Row>
				<Column>
					<Typography
						variant="headlineSmall"
						dataQA="user-artifacts"
						component="h3"
					>
						Campaigns Submitted
					</Typography>
				</Column>
			</Row>
			{
				submittedPhoneCampaignIDs.length > 0 && (
					<>
						<Row>
							<Column>
								<Typography
									variant="subHeadline"
									dataQA="user-artifacts"
									component="h4"
								>
									Text Opt-In
								</Typography>
							</Column>
						</Row>
						<Row>
							<Column>
								<ul>
									{submittedPhoneCampaignIDs.map((phoneCampaignID) => <li key={phoneCampaignID}>
										{phoneCampaignID}
									</li>)}
								</ul>
							</Column>
						</Row>
					</>
				)
			} {
				submittedEmailCampaignIDs.length > 0 && (
					<>
						<Row>
							<Column>
								<Typography
									variant="subHeadline"
									dataQA="user-artifacts"
									component="h4"
								>
									Email Capture
								</Typography>
							</Column>
						</Row>
						<Row>
							<ul>
								{submittedEmailCampaignIDs.map((emailCampaignID) => <li key={emailCampaignID}>
									{emailCampaignID}
								</li>)}
							</ul>
						</Row>
					</>
				)
			}
		</>
	);

	const emailInfo = () => (
		<>
			<Row>
				<Column>
					<Typography
						variant="headlineSmall"
						dataQA="user-artifacts"
						component="h3"
					>
						Status
					</Typography>
				</Column>
			</Row>
			<Row>
				<Column>
					<Typography
						variant="subHeadline"
						dataQA="user-artifacts"
						component="h4"
					>
						ESP
					</Typography>
				</Column>
			</Row>
			<Row>
				<Column size={SizeOptions.M}>
					<Badge
						{...getUnsubBadge(unsubIsError ? unsubErrorData : unsubSuccessData, false)}
						dataQA="unsubscribe-esp-badge"
						icon="Circle"
					/>
				</Column>
			</Row>
			<Row>
				<Column>
					<Badge
						{...getCustomerBadge(customerStateIsError ? customerStateErrorData : customerStateSuccessData)}
						dataQA="customer-esp-badge"
						icon="Circle"
					/>
				</Column>
			</Row>
			<Row>
				<Column>
					<Typography
						variant="subHeadline"
						dataQA="user-artifacts"
						component="h4"
					>
						Wunderkind
					</Typography>
				</Column>
			</Row>
			<Row>
				<Column>
					<Badge
						{...getUnsubBadge(unsubIsError ? unsubErrorData : unsubSuccessData, true)}
						dataQA="unsubscribe-wknd-badge"
						icon="Circle"
					/>
				</Column>
			</Row>
		</>
	);

	const privacyInfo = () => (
		<Row>
			<div className="general-label-wrapper">
				<Typography
					variant="bodyCopySmallBold"
					dataQA="privacyIDLookUp"
					className="sidebar-email-label"
				>
					Privacy ID Lookup
				</Typography>
			</div>
			<StyledLinkButton
				aria-label="info"
				dataQA="info"
				buttonText={privacySearchStateInput || ""}
				onClick={() => navigate({ pathname: "/tools/privacy-id" })}
			/>
		</Row>);

	return (
		<SideBar>
			<ScrollableCardWithMargin dataQA="artifact-card" className="artifacts-card" ariaLabel="artifacts card">
				{url && !isLoading() ? (
					<>
						<Typography
							variant="headline"
							dataQA="user-artifacts"
							component="h2"
						>
							User Artifacts
						</Typography>
						{websiteid ? (
							<>

								<Divider dataQA="artifacts-divider" mt="5px" mb="15px" width="full" />
								<Fields dataQa="artifacts-field" grid>
									{generalInfo()}
									{(privacySearchStateInput && privacySearchStateType) && privacyInfo()}
									{deviceIds.length > 0 && devicesInfo()}
									{isEmail && emailInfo()}
									{(submittedEmailCampaignIDs.length > 0 || submittedPhoneCampaignIDs.length > 0) && campaignInfo()}
								</Fields>
							</>

						) : <Spinner className="search-circle-loader" dataQA="circle-loader" />}

					</>
				) : (
					<Typography
						variant="headline"
						dataQA="user-artifacts"
						component="h2"
					>
						User Artifacts
					</Typography>
				)}
			</ScrollableCardWithMargin>
		</SideBar>
	);
};

export default Artifacts;

