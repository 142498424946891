import React, { forwardRef } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";

import { Input } from "@frontend/wknd-components";

type CustomInputProps = Omit<ReactDatePickerProps<string, true>, "onSelect" | "onChange" | "readOnly"> & {
	value?: string;
	id: string;
	label?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	readonly?: boolean
};

// TODO remove once we are no longer dependent on thorium
// this is needed as the base.scss thorium styles override the input
export const StyledDatePickerInput = styled(Input)`
	& input {
		padding: 0.0625rem 0.125rem;
		height: auto !important;
	}
`;

export const CustomDatePickerInput = forwardRef<HTMLInputElement, CustomInputProps>(({
	id,
	label,
	placeholderText,
	onChange,
	readonly,
	...props
}, ref) => (
	<StyledDatePickerInput
		dataQA="custom-time-picker-input"
		{...props}
		id={id}
		ref={ref}
		label={label}
		placeholder={placeholderText}
		onChange={({ event }) => event && onChange && onChange(event)}
		readOnly={readonly}
	/>
));

CustomDatePickerInput.displayName = "CustomDatePickerInput" ;
