import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors, LinkButton, spacingMap } from "@frontend/wknd-components";

export const StyledLinkButton = styled(LinkButton)`
width: calc(100% - 85px);
overflow: hidden;
white-space: nowrap;
display: block;
text-overflow: ellipsis;
`;

export const StyledDeviceLink = styled(Link)`
	height: 20px;
	> span {
		color: ${colors.midBlue};
		padding: 0 ${spacingMap.xxs};
	}

	>svg {
		padding-top: ${spacingMap.xxs};
	}

	text-decoration: none;

	&:hover {
		text-decoration: underline;
	};
	&:focus {
		text-decoration: underline;
		border-radius: 4px;
	};
	&:active {
		color: ${colors.darkBlue};
		box-shadow: none;
	};
	&:disabled {
		opacity: 33%;
		text-decoration: none;
	}
`;
