import { SearchTypes } from "./search.slice";

export const validateEmail = (email: string) => {
	const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
	return emailRegex.test(email);
};

export const validateNumber = (input: string) => {
	const positiveIntRegex = /^\d*$/;
	return positiveIntRegex.test(input);
};

export const detectType = async (input: string, hasEmailHashResults: () => Promise<boolean>): Promise<SearchTypes> => {
	if (validateEmail(input)) {
		return SearchTypes.EMAIL;
	}
	if (validateNumber(input)) {
		return input.length >= 15 ? SearchTypes.DEVICE_ID : SearchTypes.PHONE;
	}

	if (await hasEmailHashResults()) {
		return SearchTypes.EMAIL_HASH;
	}

	return SearchTypes.PHONE_HASH;
};
