export default class LocalStorage {
	/**
	 * @property {string}
	 */
	static parentKey = "uhd2-localstorage";

	/**
	 * Add namespace to key
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	static getKey(key: string) {
		if (!key) {
			return this.parentKey;
		}

		return `${this.parentKey}.${key}`;
	}

	/**
	 * Get key or return default value if key not found
	 *
	 * @param {string} key
	 * @param {*} defaultValue
	 * @returns {*}
	 */
	static get(key: string, defaultValue: unknown) {
		const k = this.getKey(key);

		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return JSON.parse(localStorage.getItem(k) || "{}") || defaultValue;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(e);
			return defaultValue;
		}
	}

	/**
	 * Set data
	 *
	 * @param {string} key
	 * @param {*} value
	 * @returns
	 */
	static set(key: string, value: unknown) {
		try {
			localStorage.setItem(this.getKey(key), JSON.stringify(value));
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(e);
		}
	}

	/**
	 * Remove an item
	 *
	 * @param {string} key
	 * @returns
	 */
	static remove(key: string) {
		return localStorage.removeItem(this.getKey(key));
	}

	/**
	 * Clear all items
	 *
	 * @returns
	 */
	static clear() {
		return localStorage.clear();
	}

	/**
	 * Get array from key or return empty array if key not found
	 *
	 * @param {string} key
	 * @param {string} separator
	 * @returns
	 */
	static getArray(key: string, separator = ",") {
		const values = localStorage.getItem(key);
		return values ? values.split(separator) : [];
	}

	/**
	 * Push element to array from key or create array if key not found
	 *
	 * @param {string} key
	 * @param {*} value
	 * @param {string} separator
	 * @returns
	 */
	static pushToArray(key: string, value: unknown, separator = ",") {
		const values = [...this.getArray(key, separator), value];
		localStorage.setItem(key, JSON.stringify(values));
	}

	/**
	 * Remove element from array from key or do nothing if key not found
	 *
	 * @param {string} key
	 * @param {*} value
	 * @param {string} separator
	 * @returns
	 */
	static removeFromArray(key: string, value: unknown, separator = ",") {
		const values = this.getArray(key, separator);
		const index = values.indexOf(String(value));
		if (index !== -1) {
			values.splice(index, 1);
			localStorage.setItem(key, JSON.stringify(values));
		}
	}
}
