export default () => {
	if (window.bouncex) {
		window.bouncex.cookie = {};
		window.setBounceCookie();
		window.bouncex.local_storage_frame?.contentWindow?.postMessage("clear_all_data", "*");
		window.location.reload();
	} else {
		alert("Wunderkind Tag is not loaded on this page");
	}
};
