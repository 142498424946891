import { ToastVariant } from "@frontend/wknd-components";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Notification = {
	text: string;
	id: number;
	variant?: ToastVariant;
	hasTimeout?: boolean;
	timeout?: number;
	trailingLinkText?: string;
	details?: string;
};

export type NotificationsState = {
	previousID: number;
	notifications: Notification[];
};

const initialState: NotificationsState = {
	previousID: 0,
	notifications: [],
};

export const appendNotification = createAsyncThunk(
	"notifications/appendNotification",
	 (notification: Notification, { getState, dispatch }) => {
		const { notifications } = getState() as { notifications: NotificationsState };
		notification.id = notifications.previousID + 1;

		if (notification.hasTimeout) {
			setTimeout(() => {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				dispatch(removeNotification(notification.id));
			}, notification.timeout);
		}

		return notification;
	},
);

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		removeNotification: (state, action: PayloadAction<number>) => {
			const notificationIndex = state.notifications.findIndex(
				(notification) => notification.id === action.payload,
			);

			if (notificationIndex > -1) {
				state.notifications.splice(notificationIndex, 1);
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(appendNotification.fulfilled, (state, action) => {
			const notification = action.payload;
			return {
				previousID: notification.id,
				notifications: [...state.notifications, notification],
			};
		});
	},
});

export const { removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
