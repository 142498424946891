import styled from "styled-components";

import { Card } from "@frontend/wknd-components";

export const ScrollableCard = styled(Card)`
	height: 73vh;
	overflow-y: auto;
`;

export const ScrollableCardWithMargin = styled(ScrollableCard)`
`;
