import { fromUnixTime } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { TIMEZONE } from "../app/constants";
import { Event } from "../search/types";

/**
 * Format timestamp from UHD backend
 */
export default (date: Event["timestamp"]) => {
	const n = Math.round(+date / 1000000000);

	const fromUnix = fromUnixTime(n);

	return formatInTimeZone(fromUnix, TIMEZONE, "PPpp");
};
