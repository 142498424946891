import React, { useEffect, useState } from "react";
import {
	Controller,
	useForm,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
	Column, Fields, Row,
} from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import { ButtonGroup, Checkbox } from "@frontend/wknd-components";
import { AnyAction } from "@reduxjs/toolkit";

import { useLazyGetEligibilityQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import formatResultTimestamp from "../date-picker/formatResultTimestamp";
import { Button, Combobox, Input } from "../form";
import DialogModal from "../form/DialogModal";
import SessionInputs from "../form/SessionInputs";
import { Navigation } from "../navigation";
import { appendNotification } from "../notifications/notifications.slice";
import { getParsedSearchState } from "../search/search.selector";
import { SearchTypes, setInput, setType, setWebsite } from "../search/search.slice";
import { formatWebsiteLabel } from "../websites/parseWebsite";

import { getItemsFromVars, getRandomIdsRequest } from "./getRandomItems";
import makeEmailHash from "./makeEmailHash";
import pushEvents from "./pushEvents";
import resetTag from "./resetTag";
import { getTriggerEventsState } from "./triggerEvents.selector";
import { addTriggerAttempt, onFormSubmit, PossibleTriggerEvents } from "./triggerEvents.slice";
import TriggerEventsResults from "./TriggerEventsResults";
import { FormData } from "./types";

import "./TriggerEvents.scss";

const RANDOM_ITEMS = 0;
const MANUAL_ITEMS = 1;

const TriggerEventsStyledDiv = styled.div`
	width: 24rem;
`;

const TriggerEvents = () => {
	const {
		websiteid: websiteidFromSearch, websitename: websitenameFromSearch, email,
	} = useSelector(getParsedSearchState);

	const dispatch = useDispatch();

	const [getEligibility] = useLazyGetEligibilityQuery();

	const [itemSelection, setItemSelection] = useState(RANDOM_ITEMS);
	const [modalOpen, setModalOpen] = useState(false);
	const [refreshSession, setRefreshSession] = useState(false);

	const onRadioButtonChange = (data: number) => {
		setItemSelection(data);
	};

	const onRefreshSessionClick = () => {
		setModalOpen(true);
	};

	useEffect(() => {
		if (refreshSession) {
			setRefreshSession(false);
			resetTag();
		}
	}, [refreshSession]);

	const {
		itemIds: itemIdsFromStore,
		...rest
	} = useSelector(getTriggerEventsState);

	const defaultValues: FormData = {
		...rest,
		websiteid: {
			value: websiteidFromSearch,
			label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
		},
		email,
		itemIds: "",
	};

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<FormData>({
		mode: "onBlur",
		defaultValues: {
			...defaultValues,
		},
	});

	const onSubmit = async ({
		websiteid: {
			value: websiteidFromForm,
			label: websitenameFromForm,
		},
		itemIds: itemIdsFromForm,
		email: emailFromForm,
		...data
	}: FormData) => {
		const eligibilityjs = getRandomIdsRequest(4, 4);
		let itemIds: string = itemIdsFromForm;

		if (itemSelection === RANDOM_ITEMS) {
			try {
				const { data: eligibilityResult } = await getEligibility({
					websiteid: websiteidFromForm,
					email: emailFromForm,
					eligibilityjs,
				});

				itemIds = getItemsFromVars(eligibilityResult).map(({ id }) => id).join();
			} catch {
				return catchErrorReturnString();
			}
		}

		try {
			const emailhash = await makeEmailHash(emailFromForm);
			const triggerAttempt = await pushEvents({
				...data, itemIds, websiteid: websiteidFromForm, email: emailFromForm,
			});

			dispatch(onFormSubmit({
				...data,
				itemIds: itemIds.split(","),
			}));

			dispatch(addTriggerAttempt({
				websiteid: websiteidFromForm,
				email: emailFromForm,
				emailhash,
				...triggerAttempt,
			}));

			dispatch(setWebsite({
				name: websitenameFromForm,
				id: websiteidFromForm,
			}));

			dispatch(setInput(emailFromForm));
			dispatch(setType(SearchTypes.EMAIL));

			const { timestamp } = triggerAttempt;

			dispatch(appendNotification({
				id: 0,
				text: `Your events were successfully triggered at ${formatResultTimestamp(timestamp)}`,
				variant: "Validation",
				hasTimeout: true,
				timeout: 3000,
			}) as unknown as AnyAction);
		} catch {
			return catchErrorReturnString();
		}

	};

	const options: {
		label: PossibleTriggerEvents;
		value: string;
	}[] = Object.entries(
		PossibleTriggerEvents,
	).map(([value, label]) => ({
		value,
		label,
	}));

	const onModalClose = () => {
		setModalOpen(false);
	};

	const onModalConfirm = () => {
		setRefreshSession(true);
	};

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Trigger Events",
				body:
					<Body maxWidth={false}>
						<DialogModal
							isOpen={modalOpen}
							onClose={onModalClose}
							onConfirm={onModalConfirm}
							onDeny={onModalClose}
						/>
						<div className="wrapper">
							<form onSubmit={handleSubmit(onSubmit)}>
								<Fields>
									<TriggerEventsStyledDiv>
										<SessionInputs
											websiteProps={{
												control,
												error: errors.websiteid,
											}}
											emailProps={{
												register,
												error: errors.email,
											}}
										/>
										<Combobox
											dataQA="event-type-select"
											name="event"
											label="Event Type"
											errorMessage="Please select an event type"
											required
											error={errors.event}
											placeholder="Select a type of event"
											options={options}
											control={control}
										/>
										<ButtonGroup
											mt="1rem"
											mb="1rem"
											ariaLabel="Item ID types"
											buttons={[
												{
													ariaLabel: "Randomize item IDs",
													buttonText: "Randomize item IDs",
													dataQA: "random-ids-button",
													onClick: () => onRadioButtonChange(RANDOM_ITEMS),
													$isDisabled: itemSelection === RANDOM_ITEMS,
												},
												{
													ariaLabel: "Manually enter item IDs",
													buttonText: "Manually enter item IDs",
													dataQA: "manual-ids-button",
													onClick: () => onRadioButtonChange(MANUAL_ITEMS),
													$isDisabled: itemSelection === MANUAL_ITEMS,
												},
											]}
											dataQA="item-ids-button-group"
										/>
										{itemSelection === MANUAL_ITEMS && (

											<Input
												label="Item IDs"
												name="itemIds"
												register={register}
												dataQA="itemids-input"
												id="itemIds"
											/>

										)}
										<Row>
											<Controller
												name="isCustomer"
												control={control}
												render={({ field }) => (
													<Checkbox
														onChange={field.onChange}
														checked={field.value}
														label="Send as Customer"
														dataQA="send-as-customer-checkbox"
													/>
												)}
											/>
										</Row>
										<Controller
											name="endVisit"
											control={control}
											/* eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars */
											render={({ field: { onChange, value, ...rest } }) => (
												<Checkbox
													mt="1rem"
													onChange={onChange}
													checked={value}
													label="Trigger end visit event"
													dataQA="trigger-end-visit-checkbox"
												/>
											)}
										/>
									</TriggerEventsStyledDiv>
									<Row>
										<Column size={SizeOptions.S}>
											<Button type="submit">Submit</Button>
										</Column>
										<Column size={SizeOptions.S}>
											<Button type="button" onClick={onRefreshSessionClick}>
												Refresh Session
											</Button>
										</Column>
									</Row>
								</Fields>
							</form>
							<div className="container">
								<TriggerEventsResults />
							</div>
						</div>
					</Body>,
			}}
		>
		</Navigation>

	);
};

export default TriggerEvents;
