import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Button, LinkButton } from "@frontend/wknd-components";

import { NS_IN_1MS } from "../app/constants";
import { RootState } from "../app/store";
import DateRange from "../date-picker/DateRange";
import { Combobox } from "../form";

import AdvancedFilters from "./AdvancedFilters";
import { getDefaultValues, getOptionsHolder } from "./filterValues";
import { allFilterTypesMap, filterTypesMap, getDateFilters, getFilters } from "./search.selector";
import {
	applyFilters, FilterBys, FilterData, FilterValue, isFilterEmpty, setDatetimeFilter, setFilters,
} from "./search.slice";
import { Event } from "./types";

const filterTypes = Array.from(filterTypesMap.values());

interface FiltersProps {
	events: Event[],
}

const StyledRow = styled.div`
	align-items: flex-start;
	display:flex;
`;

const FilterButtonsWrapper = styled.div`
	display: flex;
	width: auto;
	margin-right: 1%;
`;

const DivWidthMargin = styled.div<{ half?: true, noMargin?: true }>`
	width: ${(props) => props.half ? "10%" : "20%"};
	margin-right: ${(props) => props.noMargin ? "0px" : "1%"};
`;

const Filters = ({ events = [] }: FiltersProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const filters = useSelector((state: RootState) => getFilters(state));
	const datetimeFilters = useSelector((state: RootState) => getDateFilters(state));
	const defaultStartTimeFilter = datetimeFilters?.start ? new Date(datetimeFilters?.start / NS_IN_1MS) : null;
	const defaultEndTimeFilter = datetimeFilters?.end ? new Date(datetimeFilters?.end / NS_IN_1MS) : null;
	const [startDateAndTime, setStartDateAndTime] = useState<(Date | null)>(defaultStartTimeFilter);
	const [endDateAndTime, setEndDateAndTime] = useState<Date | null>(defaultEndTimeFilter);
	const [advancedModal, setAdvancedModal] = useState<boolean>(false);
	const [URLParams] = useSearchParams();
	const [defaultValues, setDefaultValues] = useState(getDefaultValues(filterTypes, filters));
	const optionsHolder = getOptionsHolder(filterTypes, events);

	const {
		control,
		handleSubmit,
		reset,
	} = useForm<FilterData>({ defaultValues });

	const hasDates = (startDateAndTime !== null || endDateAndTime !== null ||
		URLParams.get("startDateTime") !== null || URLParams.get("endDateTime") !== null);

	const formValues: FilterData = useWatch({ control });

	useEffect(() => {
		if (filters) {
			let passedFilters = filters ?? {};
			setDefaultValues(passedFilters);
			reset(passedFilters);
		}
	}, [filters, reset]);

	useEffect(() => {
		if (datetimeFilters) {
			const startDateFilter = datetimeFilters?.start ? new Date(datetimeFilters?.start / NS_IN_1MS) : null;
			setStartDateAndTime(startDateFilter);

			const endDateFilter = datetimeFilters?.end ? new Date(datetimeFilters?.end / NS_IN_1MS) : null;
			setEndDateAndTime(endDateFilter);
		}

	}, [datetimeFilters]);


	const checkForValues = (values: FilterValue | FilterValue[] | null): boolean => {
		if (!values) {
			return false;
		}

		if (!Array.isArray(values)) {
			return values.value !== "";
		}

		return values.some((value) => checkForValues(value));
	};

	const hasFilters = Object.entries(formValues).filter(
		([, value]) => checkForValues(value),
	).length > 0 || !isFilterEmpty(filters) || hasDates !== false;

	const buildEventNameParam = (eventname?: FilterValue | FilterValue[] | undefined) => {
		const eventTypeFilter = Array.isArray(eventname) ? (eventname)?.map(({ value }) => value).toString()
			: (eventname as FilterValue)?.value?.toString();
		if (eventTypeFilter) {
			URLParams.set("eventType", eventTypeFilter);
		}
	};

	const buildCampaignIDParam = (campaignid?: FilterValue) => {
		const stringifiedCampaignIdParam = campaignid?.value?.toString();
		if (stringifiedCampaignIdParam) {
			URLParams.set("campaignid", stringifiedCampaignIdParam);
		}
	};

	// using navigate and createSearchParams instead of setSearchParams allows for back functionality and removing one param at a time
	const buildAndUpdateURLParams = (data: FilterData) => {
		buildEventNameParam(data[FilterBys.EVENT_TYPE]);
		buildCampaignIDParam(data[FilterBys.CAMPAIGN_ID] as FilterValue);
		const stringifiedURLparams = createSearchParams(URLParams).toString();
		navigate({ pathname: "/results", search: stringifiedURLparams });
	};

	// When we hit "submit", we want to clear the regular filters and rebuild, but when we hit "clear" we want to clear both the regular and advanced filters. shouldClearAllParams signifies if it clearing all params, or just the regular ones
	const clearFilterParams = (shouldClearAllParams: boolean) => {
		const map = shouldClearAllParams ? allFilterTypesMap : filterTypesMap;
		let paramsArray = ["startDateTime", "endDateTime", ...Array.from(map.keys())];

		paramsArray.forEach((filter) => {
			if (typeof filter === "string" && filter != "") {
				URLParams.delete(filter);
			}
		});
	};

	const onSubmit = (data: FilterData) => {
		clearFilterParams(false);
		if (startDateAndTime || endDateAndTime) {
			const start = startDateAndTime && startDateAndTime.getTime() * NS_IN_1MS;
			const end = endDateAndTime && endDateAndTime.getTime() * NS_IN_1MS;

			if (start) {
				URLParams.set("startDateTime", start?.toString());
			}
			if (end) {
				URLParams.set("endDateTime", end?.toString());
			}
			dispatch(setDatetimeFilter({ start, end }));
		} else {
			dispatch(setDatetimeFilter(null));
		}
		buildAndUpdateURLParams(data);
		dispatch(setFilters(data));
		dispatch(applyFilters());
	};

	const onClear = () => {
		clearFilterParams(true);
		const stringifiedURLparams = createSearchParams(URLParams).toString();
		navigate({ pathname: "/results", search: stringifiedURLparams });
		dispatch(setFilters(null));
		dispatch(setDatetimeFilter(null));
		dispatch(applyFilters());
		setStartDateAndTime(null);
		setEndDateAndTime(null);
		reset(getDefaultValues(filterTypes, {}));
	};

	return (
		<>
			<StyledRow>
				<DivWidthMargin>
					<Combobox
						dataQA="event-type-select"
						placeholder="Event type..."
						isClearable
						options={optionsHolder[FilterBys.EVENT_TYPE]}
						label="Event Type"
						name="eventname"
						control={control}
						isMulti
					/>
				</DivWidthMargin>
				<DivWidthMargin>
					<Combobox
						dataQA="campaign-id-select"
						placeholder="Campaign ID..."
						isClearable
						options={optionsHolder[FilterBys.CAMPAIGN_ID]}
						label="Campaign ID"
						name="campaignid,parentcampaignid"
						control={control}
					/>
				</DivWidthMargin>
				<DateRange
					setStartDate={setStartDateAndTime}
					setEndDate={setEndDateAndTime}
					label="Date Range"
					id="date-range"
					startDate={startDateAndTime}
					endDate={endDateAndTime}
					// note: this assumes that events coming back from BE are ordered by date
					minDate={new Date(+events[events.length - 1]?.timestamp / NS_IN_1MS || 0)}
					maxDate={new Date()}
				/>
				<FilterButtonsWrapper>
					<LinkButton
						buttonText="Advanced"
						rightIcon="Filter"
						dataQA="advanced-filters-button"
						onClick={() => setAdvancedModal(true)}
						mt="2rem"
					/>
				</FilterButtonsWrapper>
				<FilterButtonsWrapper>
					<Button
						variant="primary"
						buttonText="Apply"
						onClick={handleSubmit(onSubmit)}
						dataQA="apply-button"
						mt="1.6rem"
						disabled={!hasFilters}
					/>
				</FilterButtonsWrapper>
				<FilterButtonsWrapper>
					<LinkButton
						buttonText="Clear"
						onClick={onClear}
						dataQA="clear-button"
						mt="2rem"
						disabled={!hasFilters}
					/>
				</FilterButtonsWrapper>
			</StyledRow>
			<AdvancedFilters events={events} isOpen={advancedModal} setOpen={setAdvancedModal} />
		</>
	);
};

export default Filters;
