import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Row } from "@frontend/thorium232/dist/fieldset";
import { Divider, Spinner, Typography } from "@frontend/wknd-components";

import { catchErrorReturnString } from "../app/errorHandling";
import { RootState } from "../app/store";
import { ScrollableCard } from "../form/ScrollableCard";
import useWebsites from "../websites/useWebsites";

import Filters from "./Filters";
import Results from "./Results";
import { allFilterTypesMap, getDateFilters, getFilters, getSearchState } from "./search.selector";
import {
	applyFilters,
	clearSearch,
	FilterData,
	FilterValue,
	filterValueArraysAreEqual,
	SearchTypes,
	setDatetimeFilter,
	setFilters,
	setInput,
	setLastActiveDevices,
	setResult,
	setType,
	setWebsite,
} from "./search.slice";
import useSearch from "./useSearch";

import "./Search.scss";

const SearchResultsPage = () => {
	const dispatch = useDispatch();

	const [URLParams] = useSearchParams();
	const websiteId = URLParams.get("websiteId");
	const searchType = URLParams.get("searchType");
	const searchInput = URLParams.get("searchInput");
	const startDatetime = URLParams.get("startDateTime") ?? 0;
	const endDatetime = URLParams.get("endDateTime") ?? 0;
	const hasSearch = searchInput != null && searchType != null && websiteId != null;
	const filters = useSelector((state: RootState) => getFilters(state));
	const previousDateFilters = useSelector((state: RootState) => getDateFilters(state));
	const isPreviousDateFilters = previousDateFilters?.start === startDatetime
		&& previousDateFilters?.end === endDatetime;
	let isPreviousFilter = true;
	const { getWebsiteById } = useWebsites();

	const {
		result,
		filteredResult,
		websiteid: previousSearchedWebsiteid,
		input: previousSearchedInput,
		type: previousSearchedType,
	} = useSelector(getSearchState);
	const [getResults] = useSearch();
	const [isLoading, setIsLoading] = useState(false);


	// isPreviousFilter and these functions are added so that filters would be reapplied when user hits the back button/doesn't land on the page with a new render
	const isPreviousFilterValue = (previousFilter: FilterValue | FilterValue[] | null | undefined,
		formattedParam: FilterValue | FilterValue[]) => {
		if (Array.isArray(previousFilter)) {
			if (!Array.isArray(formattedParam)) {
				return previousFilter.length === 1 ? formattedParam.value === previousFilter[0].value : false;
			}
			return filterValueArraysAreEqual(previousFilter, formattedParam);
		}
		return !Array.isArray(formattedParam) && previousFilter?.value === formattedParam?.value;
	};

	const formattedFilterData: FilterData = {};
	allFilterTypesMap.forEach((filterName, paramName) => {
		const param = URLParams.get(paramName) || "";
		const previousFilter = filters && filters[filterName] as FilterValue | FilterValue[] | null | undefined;
		const formattedParam: FilterValue | FilterValue[] = param.includes(",") ?
			param.split(",")?.map((value) => ({ label: value, value: value }))
			: { label: param, value: param };

		isPreviousFilter = isPreviousFilter && isPreviousFilterValue(previousFilter, formattedParam);
		formattedFilterData[filterName] = formattedParam;
	});

	const submitSearch = async () => {
		try {
			dispatch(setWebsite({
				id: websiteId as string,
				name: getWebsiteById(websiteId as string)?.name ?? "",
			}));

			dispatch(setType(searchType as SearchTypes));
			dispatch(setInput(searchInput as string));

			const { events, lastActiveDevices } = await getResults(
				searchInput as string,
				websiteId as string,
				searchType as SearchTypes,
			);

			dispatch(setFilters(formattedFilterData));
			dispatch(setDatetimeFilter({ start: Number(startDatetime), end: Number(endDatetime) }));

			// Set current search attributes and results in Redux Store
			dispatch(setResult(events));
			dispatch(applyFilters());
			dispatch(setLastActiveDevices(lastActiveDevices));
			setIsLoading(false);
		} catch {
			return catchErrorReturnString();
		}
	};

	const isPreviousSearch = websiteId === previousSearchedWebsiteid
		&& searchInput === previousSearchedInput
		&& searchType === previousSearchedType;

	useEffect(() => {
		if (hasSearch && (!isPreviousSearch || !isPreviousFilter || !isPreviousDateFilters)) {
			setFilters(null);
			// Reset store
			clearSearch();
			// if different search, re dispatch, else use store
			submitSearch();
			// only showing loading spinner if performing a new search
			if (!isPreviousSearch) {
				setIsLoading(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPreviousSearch, searchInput, searchType, websiteId, URLParams, isPreviousFilter, isPreviousDateFilters]);

	return (

		<ScrollableCard dataQA="search-results-card" ariaLabel="search-card">
			<Typography
				variant="headline"
				dataQA="activity timeline"
				component="h2"
			>
				Activity Timeline
			</Typography>
			<Divider dataQA="search-page-divider" mt="5px" mb="15px" width="full" />
			<Filters events={result || []} />
			{isLoading ? <div className="spinner-wrapper"><Spinner dataQA="spinner" /></div> :
				<Row dataQa="search-results-row">
					<Results events={filteredResult || result || []} hasSearch={hasSearch}></Results>
				</Row>
			}
		</ScrollableCard >
	);
};

export default SearchResultsPage;
