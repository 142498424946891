import React from "react";
import enUS from "date-fns/locale/en-US";
import DatePicker, { registerLocale } from "react-datepicker";
import styled, { css } from "styled-components";

import { CustomDatePickerInput } from "./CustomDatePickerInput";
import CustomTimeInput from "./CustomTimeInput";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("en-US", enUS);

export type DateRangeProps = {
	label?: string;
	id: string;
	startDate?: Date | null;
	endDate?: Date | null;
	minDate?: Date | null;
	maxDate?: Date | null;
	setStartDate: React.Dispatch<React.SetStateAction<(Date | null)>>;
	setEndDate: React.Dispatch<React.SetStateAction<(Date | null)>> | (() => void);
};

export const datePickerCloseIconStyles = css`
	.react-datepicker__close-icon {
		top: 10px;
	}
`;

const DatePickerWrapper = styled.div`
	${datePickerCloseIconStyles};
	width: 20%;
	margin-right: 1%;
`;

export const validateAndSetTime = (
	date: Date | null,
	setTime: React.Dispatch<React.SetStateAction<Date | null>>,
) => {
	if (date?.toString() !== "Invalid Date") {
		setTime(date);
	} else {
		setTime(null);
	}
};

const DateRange = ({
	label,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	id,
	minDate,
	maxDate,
	...props
}: DateRangeProps) => (
	<>
		<DatePickerWrapper>
			<DatePicker
				id="start-date"
				aria-label={label}
				selected={startDate}
				minDate={minDate}
				maxDate={maxDate}
				onChange={(date) => {
					validateAndSetTime(date, setStartDate);
				}}
				dateFormat="MMMM d, yyyy h:mmaa"
				startDate={startDate}
				endDate={endDate}
				locale="en-US"
				selectsStart
				showTimeInput
				customInput={<CustomDatePickerInput
					id="start-date"
					label="Start Date and Time"
					placeholderText="Start Date and Time"
					{...props}
				/>}
				isClearable={startDate !== null}
				customTimeInput={<CustomTimeInput {...props} />}
				// keeping the calendar open in case the user wants to also input a time
				// clicking another button, outside of the calendar or hitting escape will still close the calendar
				shouldCloseOnSelect={false}
			/>
		</DatePickerWrapper>
		<DatePickerWrapper>
			<DatePicker
				id="end-date"
				aria-label={label}
				selected={endDate}
				minDate={minDate}
				maxDate={maxDate}
				onChange={(newEndDate) => {
					if (newEndDate) {
						if ((startDate?.getTime() === newEndDate?.getTime()) || !endDate) {
							validateAndSetTime(new Date(newEndDate.setHours(23, 59, 59, 999)), setEndDate);
						} else {
							validateAndSetTime(newEndDate, setEndDate);
						}
					} else {
						validateAndSetTime(null, setEndDate);
					}

				}}
				startDate={startDate}
				dateFormat="MMMM d, yyyy h:mmaa"
				endDate={endDate}
				selectsEnd
				showTimeInput
				locale="en-US"
				customInput={<CustomDatePickerInput
					id="end-date"
					label="End Date and Time"
					placeholderText="End Date and Time"
					{...props}
				/>}
				isClearable={endDate !== null}
				customTimeInput={<CustomTimeInput {...props} />}
				// keeping the calendar open in case the user wants to also input a time
				// clicking another button, outside of the calendar or hitting escape will still close the calendar
				shouldCloseOnSelect={false}
			/>
		</DatePickerWrapper>
	</>
);

export default DateRange;
