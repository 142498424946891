import styled from "styled-components";

import { Button, Card } from "@frontend/wknd-components";

export const StyledCard = styled(Card)`
	width: 90%;
	margin: auto;
`;

export const DivSearchFields = styled.div`
	width: 40%;
	margin-right: 1%;
`;

export const StyledRow = styled.div`
	align-items: flex-start;
	display:flex;
	justify-items: center;
	justify-content: space-around;
`;

export const SearchButtonWrapper = styled.div`
	display: grid;
	width: 9%;
`;

export const StyledResults = styled.div`
	width: 90%;
	margin: 20px auto;
`;

export const StyledWebsiteName = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex: 1 0 0;
`;

export const StyledDetachButton = styled(Button)`
	text-wrap: balance;
`;
