import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import { Button } from "@frontend/wknd-components";

import { useLazyGetEligibilityQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import { Input } from "../form";
import SessionInputs from "../form/SessionInputs";
import { Navigation } from "../navigation";
import { getParsedSearchState } from "../search/search.selector";
import { SearchTypes, setInput, setType, setWebsite } from "../search/search.slice";
import { validateEmail } from "../search/searchTypes";
import { formatWebsiteLabel, parseWebsiteFromForm } from "../websites/parseWebsite";

import EligibilityTextEditors from "./EligibilityEditors";
import EligibilityResults from "./EligibilityResults";
import { GetEligibilityRequest, GetEligibilityResponse } from "./types";

import "./Eligibility.scss";

const EligibilityStyledDiv = styled.div`
	width: 40rem;
`;

export interface EligibilityFormData {
	websiteid: {
		label: string;
		value: string;
	};
	email: string;
	deviceid: string;
	visitid: string;
	timestamp: string;
	triggerEventQueryString: string;
	eligibilityjs: string;
	campaigneligibilityjs: string;
	campaignid: string;
	testmode: boolean;
	subscriberespsettings: string;
	customerespsettings: string;
	urllookupespsettings: string;
}

const Eligibility = () => {
	const { websiteid, websitename, email, deviceid } = useSelector(getParsedSearchState);

	const dispatch = useDispatch();
	const [getEligibility] = useLazyGetEligibilityQuery();
	const [eligibilityResults, setEligibilityResults] = useState<GetEligibilityResponse>();

	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<EligibilityFormData>({
		mode: "onBlur",
		defaultValues: {
			websiteid: {
				value: websiteid,
				label: formatWebsiteLabel({ label: websitename, value: websiteid }),
			},
			email,
			deviceid,
			eligibilityjs: "",
			campaigneligibilityjs: "",
			campaignid: "",
			testmode: false,
			subscriberespsettings: "",
			customerespsettings: "",
			urllookupespsettings: "",
		},
	});

	const onSubmit = async ({
		websiteid: website,
		email: emailFromForm,
		deviceid: deviceidFromForm,
		eligibilityjs: eligibilityjsFromForm,
		campaignid: campaignidFromForm,
		testmode: testmodeFromForm,
		campaigneligibilityjs: campaigneligibilityjsFromForm,
		visitid: visitidFromForm,
		triggerEventQueryString: triggerEventQueryStringFromForm,
		subscriberespsettings: subscriberespsettingsFromForm,
		customerespsettings: customerespsettingsFromForm,
		urllookupespsettings: urllookupespsettingsFromForm,
	}: EligibilityFormData) => {
		dispatch(setWebsite(parseWebsiteFromForm(website)));

		const eligibilityData: GetEligibilityRequest = {
			websiteid: website.value,
			eligibilityjs: encodeURIComponent(campaigneligibilityjsFromForm),
			testmode: testmodeFromForm,
			visitid: visitidFromForm,
			disable_cache: 1,
			parentcampaign: campaignidFromForm,
			websiteeligibilityjs: encodeURIComponent(eligibilityjsFromForm).replaceAll("'", "%27"),
			triggerquerystring: triggerEventQueryStringFromForm,
			espsettings: subscriberespsettingsFromForm,
			customerstateespsettings: customerespsettingsFromForm,
			lookupespsettings: urllookupespsettingsFromForm,
		};

		if (emailFromForm !== "") {
			eligibilityData.email = emailFromForm;
			dispatch(setType(validateEmail(emailFromForm) ? SearchTypes.EMAIL : SearchTypes.EMAIL_HASH));
			dispatch(setInput(emailFromForm));
		}

		if (deviceidFromForm !== "") {
			eligibilityData.deviceid = deviceidFromForm;
			dispatch(setType(SearchTypes.DEVICE_ID));
			dispatch(setInput(deviceidFromForm));
		}

		try {
			const { isSuccess, data } = await getEligibility(eligibilityData);
			if (isSuccess) {
				setEligibilityResults(data);
			}
		} catch {
			return catchErrorReturnString();
		}

	};

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Eligibility",
				body:
					<Body maxWidth={false}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Fields>
								<EligibilityStyledDiv>
									<SessionInputs
										websiteProps={{
											control,
											error: errors.websiteid,
										}}
										emailProps={{
											register,
											error: errors.email,
											required: {
												validate: {
													checkForDeviceID: (v: string) => {
														if (v !== "") {
															if (getValues("deviceid") === "") {
																return validateEmail(v) || "Valid email is required if no Device ID";
															} else {
																return "Email cannot be used if a device ID is provided.";
															}
														}
													},
													isEmail: (v: string) => {
														if (getValues("deviceid") === "") {
															return validateEmail(v) || "Valid email is required if no Device ID";
														} else {
															return;
														}
													},
												},
											},
										}}
									/>
									<Input
										label="Device ID"
										name="deviceid"
										error={errors.deviceid}
										validators={{
											validate: {
												checkForEmail: (v) => {
													if (v !== "" && getValues("email") !== "") {
														return "Device ID cannot be used if an email is provided.";
													}
												},
											},
										}}
										register={register}
										id="deviceid"
										placeholder="Device ID"
										dataQA="deviceid-input"
									/>
									<Input
										label="Visit ID"
										name="visitid"
										placeholder="Visit ID"
										register={register}
										id="visitid"
										dataQA="visitid-input"
									/>
									<Input
										label="Trigger Event Querystring"
										name="triggerEventQueryString"
										register={register}
										placeholder="Trigger Event Querystring"
										id="triggerEventQueryString"
										dataQA="triggerEventQueryString-input"
									/>
								</EligibilityStyledDiv>

								<EligibilityTextEditors
									register={register}
									control={control}
									setValue={setValue}
									getValues={getValues}
								/>
								<Row>
									<Column size={SizeOptions.S}>
										<Button
											buttonText="Submit"
											variant="primary"
											dataQA="submit"
											type="submit"
										/>
									</Column>
								</Row>
							</Fields>
						</form>
						{eligibilityResults && <EligibilityResults result={eligibilityResults} />}
					</Body>,
			}}
		/>
	);
};

export default Eligibility;
