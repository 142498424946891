/* eslint-disable @typescript-eslint/unbound-method */
import { useDispatch } from "react-redux";

import { AnyAction } from "@reduxjs/toolkit";

import { useLazyGetDeviceEventsQuery,
	useLazyGetEmailEventsQuery, useLazyGetPhoneEventsQuery } from "../app/api";
import { appendNotification } from "../notifications/notifications.slice";

import { SearchTypes } from "./search.slice";
import { BaseEventsResponse, Event } from "./types";

const useSearch = (): [(input: string, websiteid: string, type: SearchTypes) => Promise<{
	events: Event[] | null;
	lastActiveDevices: Record<string, number>;
}>, boolean] => {
	const dispatch = useDispatch();
	const [getEmailEvents, { isLoading: isEmailEventsLoading }] = useLazyGetEmailEventsQuery();
	const [getDeviceEvents, { isLoading: isDeviceEventsLoading }] = useLazyGetDeviceEventsQuery();
	const [getPhoneEvents, { isLoading: isPhoneEventsLoading }] = useLazyGetPhoneEventsQuery();

	const eventsRequest = async (requestfn: () => Promise<BaseEventsResponse>): Promise<{
		events: Event[] | null,
		lastActiveDevices: Record<string, number>,
	}> => {
		let lastActiveDevices: Record<string, number> = {};
		try {
			const res = await requestfn();
			if (!res.success || !("events" in res)) {
				return { events: null, lastActiveDevices };
			}

			// email events response have lastactivedevices object, while device events response has only one date
			if ("lastactivedevices" in res) {
				lastActiveDevices = res.lastactivedevices;
			} else if ("lastEventDate" in res && "deviceid" in res.events[0]) {
				lastActiveDevices = { ...lastActiveDevices, [res.events[0].deviceid]: res.lastEventDate };
			}

			return { events: res.events, lastActiveDevices };
		} catch {
			return { events: null, lastActiveDevices };
		}
	};

	const submitSearch = async (
		input: string,
		websiteid: string,
		type: SearchTypes,
	): Promise<{
		events: Event[] | null,
		lastActiveDevices: Record<string, number>,
	}> => {
		let response: {
			events: Event[] | null,
			lastActiveDevices: Record<string, number>,
		} = {
			events: null,
			lastActiveDevices: {},
		};
		switch (type) {
			case SearchTypes.EMAIL:
				response = await eventsRequest(getEmailEvents({ email: input, websiteid }).unwrap);
				break;
			case SearchTypes.EMAIL_HASH:
				response = await eventsRequest(getEmailEvents({ emailhash: input, websiteid }).unwrap);
				break;
			case SearchTypes.DEVICE_ID:
				response = await eventsRequest(getDeviceEvents({ deviceid: input, websiteid }).unwrap);
				break;
			case SearchTypes.PHONE:
				response = await eventsRequest(getPhoneEvents({ phone: input, websiteid }).unwrap);
				break;
			case SearchTypes.PHONE_HASH:
				response = await eventsRequest(getPhoneEvents({ phonehash: input, websiteid }).unwrap);
				break;
			default:
				dispatch(appendNotification({
					id: 0,
					text: `Uncaught TypeError: Unknown search type: ${type}.`,
					variant: "Error",
					hasTimeout: false,
				}) as unknown as AnyAction);
				response = { events: null, lastActiveDevices: {} };
		}

		return response;
	};

	return [submitSearch, isEmailEventsLoading || isDeviceEventsLoading || isPhoneEventsLoading];
};

export default useSearch;
