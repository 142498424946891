import React, { useRef } from "react";

import {
	Modal, ModalBody, ModalFooter, ModalHeader, ModalHeading, ModalHeadingProps, StyledModalProps, Typography,
} from "@frontend/wknd-components";

interface DialogModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	onDeny: () => void;
	label?: string;
	icon?: ModalHeadingProps["headingIcon"];
	bodyText?: string;
	size?: StyledModalProps["size"];
	dataQA?: string;
}

const DialogModal = ({
	isOpen,
	onClose,
	onConfirm,
	onDeny,
	label = "You may have unsaved work.",
	bodyText = "You may have unsaved work, are you sure you want to refresh?",
	icon = "AlertCircle",
	size = "small",
	dataQA = "dialog-modal",
}: DialogModalProps) => {
	const appElement = useRef<HTMLDivElement>(document.createElement("div"));

	return (
		<div ref={appElement}>
			<Modal
				appElement={appElement.current as HTMLElement}
				isOpen={isOpen}
				contentLabel={`${dataQA}`}
				dataQA={`${dataQA}`}
				onRequestClose={onClose}
				size={size}
			>
				<ModalHeader dataQA={`${dataQA}-header`}>
					<ModalHeading
						component="h1"
						dataQA={`${dataQA}-heading`}
						headingIcon={icon}
					>
						{label}
					</ModalHeading>
				</ModalHeader>
				<ModalBody dataQA={`${dataQA}-body`}>
					<Typography dataQA={`${dataQA}-body-text`} variant="bodyCopy" component="p" m="0">
						{bodyText}
					</Typography>
				</ModalBody>
				<ModalFooter
					dataQA={`${dataQA}-footer`}
					primaryButtonOnClick={onConfirm}
					primaryButtonText="Yes"
					secondaryButtonOnClick={onDeny}
					secondaryButtonText="No"
				/>
			</Modal>
		</div>
	);
};

export default DialogModal;
