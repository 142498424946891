import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SearchTypes } from "./search.slice";

export type InitialSearchState = {
	type: SearchTypes;
	websiteid: string;
	input: string;
};

const search: InitialSearchState = {
	type: SearchTypes.AUTO,
	websiteid: "",
	input: "",
};

const initialState: InitialSearchState = { ...search };

// the initial search has its own slice because it is independant from search
const initialSearchSlice = createSlice({
	name: "initialSearch",
	initialState,
	reducers: {
		setInitialSearch: (state, { payload }: PayloadAction<InitialSearchState>) => {
			state = payload;

			return state;
		},
	},
});

export const { setInitialSearch } = initialSearchSlice.actions;

export default initialSearchSlice.reducer;

