import React from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

import {
	useLazyGetCampaignEligibilityQuery,
	useLazyGetWebsiteEligibilityQuery,
	useLazyGetWebsiteESPSettingsQuery,
} from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";

import EligibilityEditor from "./EligbilityIndividualEditor";
import { EligibilityEditorProps } from "./EligbilityIndividualEditor";
import { EligibilityFormData } from "./Eligibility";

interface EligibilityTextEditorProps {
	register: UseFormRegister<EligibilityFormData>;
	setValue: UseFormSetValue<EligibilityFormData>;
	control: Control<EligibilityFormData>;
	getValues: UseFormGetValues<EligibilityFormData>;
}

export enum ESPSettingTypes {
	SUBSCRIBER = "subscriberespsettings",
	CUSTOMER = "customerespsettings",
	URL = "urllookupespsettings",
}


const EligibilityTextEditors = ({
	control, register, setValue, getValues,
}: EligibilityTextEditorProps) => {
	const [getWebsiteEligibility] = useLazyGetWebsiteEligibilityQuery();
	const [getCampaignEligibility] = useLazyGetCampaignEligibilityQuery();
	const [getWebsiteESPSettings] = useLazyGetWebsiteESPSettingsQuery();
	const getWebsiteId = () => getValues("websiteid").value;

	const callGetWebsiteEligibility = async () => {
		try {
			const res = await getWebsiteEligibility({ websiteid: getWebsiteId() }).unwrap();
			if (!res.success || !("eligibilityjs" in res)) {
				return;
			}
			setValue("eligibilityjs", res.eligibilityjs);
		} catch {
			return catchErrorReturnString();
		}

	};

	const callGetCampaignEligibility = async () => {
		try {
			const { testMode, success, ...res } = await getCampaignEligibility({
				campaignid: getValues("campaignid"),
				websiteid: getWebsiteId(),
			}).unwrap();
			if (!success || !("eligibilityjs" in res)) {
				return;
			}

			if (res.eligibilityjs != "") {
				setValue("campaigneligibilityjs", res.eligibilityjs);
				return;
			}

			let composedEligibilityJs = `${res.eligibilitycriteriajs}${res.eligibilitythresholdsjs}${res.renderoptionsjs}`;

			composedEligibilityJs = composedEligibilityJs.includes("Eligible()") ? composedEligibilityJs :
				`${composedEligibilityJs}Eligible();`;

			setValue("campaigneligibilityjs", composedEligibilityJs);
			setValue("testmode", testMode);
		} catch {
			return catchErrorReturnString();
		}
	};

	const callGetWebsiteESPSettings = async (type: string) => {
		try {
			const params = {
				websiteid: getWebsiteId(),
				lookup: type === ESPSettingTypes.URL,
				customer_state: type === ESPSettingTypes.CUSTOMER,
			};
			const res = await getWebsiteESPSettings(params).unwrap();
			if (!res?.success || !("espsettings" in res)) {
				return;
			}

			const settings = JSON.stringify(res?.espsettings, null, "\t");
			setValue(type as keyof EligibilityFormData, settings);
		} catch {
			return catchErrorReturnString();
		}
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	/* eslint-disable @typescript-eslint/no-misused-promises */
	// we are already disabling this rule for attributes, and these are basically component attributes
	const eligibilityEditors: EligibilityEditorProps<any>[] = [
		{
			name: "eligibilityjs",
			editorMode: "javascript",
			labelName: "Website Eligibility JS",
			buttonText: "Get Website Eligibility",
			buttonOnClick: callGetWebsiteEligibility,
			editorOnChange(value) {
				setValue("eligibilityjs", value);
			},
		},
		{
			name: "campaigneligibilityjs",
			editorMode: "javascript",
			labelName: "Eligibility JS",
			buttonText: "Get Campaign Eligibility",
			buttonOnClick: callGetCampaignEligibility,
			editorOnChange(value) {
				setValue("campaigneligibilityjs", value);
			},
			checkboxLabel: "Testmode",
			checkboxName: "testmode",
			input: {
				name: "campaignid",
				label: "Campaign ID",
				placeholder: "Enter Campaign Id",
				dataQA: "campaignid-input",
				id: "campaignid",
				register: register,
			},
		},
		{
			name: "subscriberespsettings",
			editorMode: "json",
			labelName: "Subscriber ESP Settings",
			buttonText: "Get Website ESP Settings",
			buttonOnClick: () => callGetWebsiteESPSettings("subscriberespsettings"),
			editorOnChange(value) {
				setValue("subscriberespsettings", value);
			},
		},
		{
			name: "customerespsettings",
			editorMode: "json",
			labelName: "Customer ESP Settings",
			buttonText: "Get Customer State ESP Settings",
			buttonOnClick: () => callGetWebsiteESPSettings("customerespsettings"),
			editorOnChange(value) {
				setValue("customerespsettings", value);
			},
		},
		{
			name: "urllookupespsettings",
			editorMode: "json",
			labelName: "URL Look Up ESP Settings",
			buttonText: "Get URL Look Up ESP Setting",
			buttonOnClick: () => callGetWebsiteESPSettings("urllookupespsettings"),
			editorOnChange(value) {
				setValue("urllookupespsettings", value);
			},
		},
	];


	return (
		<>
			{eligibilityEditors.map((e, index) => (
				<EligibilityEditor
					key={index}
					control={control}
					name={e.name}
					editorMode={e.editorMode}
					labelName={e.labelName}
					buttonText={e.buttonText}
					buttonOnClick={e.buttonOnClick}
					editorOnChange={e.editorOnChange}
					input={e.input}
					checkboxLabel={e.checkboxLabel}
					checkboxName={e.checkboxName}
				/>
			))}
		</>


	);
};

export default EligibilityTextEditors;
