import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { Button, LinkButton } from "@frontend/wknd-components";
import { AnyAction } from "@reduxjs/toolkit";

import { useLazySendEmailQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import Editor from "../editor/Editor";
import { Input } from "../form";
import { Navigation } from "../navigation";
import { appendNotification } from "../notifications/notifications.slice";
import { getParsedSearchState } from "../search/search.selector";
import { validateEmail } from "../search/searchTypes";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";

import { SendEmailFormData, SendEmailRequest } from "./types";

import "./SendEmail.scss";

type SendEmailProps = {
	defaultEmailJob?: string
};

const DEFAULT_EMAIL_JOB = `{
	"From": "test@wunderkind.co",
	"FromName": "Test Email",
	"Body": "<html><body><p>test</p></body></html>",
	"EspCreds": "bx/sendgrid",
	"Subject": "the subject" ,
	"EspExtraFields": {}
}`;

export const DEFAULT_ESP_CREDS = "";

const SendEmail = ({
	defaultEmailJob = DEFAULT_EMAIL_JOB,
}: SendEmailProps) => {
	const dispatch = useDispatch();

	const {
		websiteid: websiteidFromSearch, websitename: websitenameFromSearch, email: emailFromSearch,
	} = useSelector(getParsedSearchState);

	const {
		control,
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm<SendEmailFormData>({
		mode: "onBlur",
		defaultValues: {
			websiteid: {
				label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
				value: websiteidFromSearch,
			},
			email: emailFromSearch,
			emailjob: defaultEmailJob,
			espCreds: DEFAULT_ESP_CREDS,
		},
	});

	const [sendEmail] = useLazySendEmailQuery();

	const onSubmit = async ({
		websiteid,
		email,
		emailjob,
		espCreds,
	}: SendEmailFormData) => {
		const sendEmailData: SendEmailRequest = {
			websiteid: websiteid.value,
			email,
			emailjob,
			espCreds,
		};

		if (espCreds) {
			sendEmailData.espCreds = espCreds;
		}

		try {
			const { isSuccess } = await sendEmail(sendEmailData);

			if (isSuccess) {
				dispatch(appendNotification({
					id: 0,
					text: "Email successfully sent",
					variant: "Validation",
					hasTimeout: false,
				}) as unknown as AnyAction);
			}
		} catch {
			return catchErrorReturnString();
		}

	};

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Send Email",
				body:
					<Body maxWidth={false}>
						<div className="send-email-wrapper">
							<form onSubmit={handleSubmit(onSubmit)}>
								<Fields>
									<Row>
										<Column>
											<WebsitePicker
												control={control}
												error={errors.websiteid}
												label="Website ID / Name"
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<Input
												label="Email"
												placeholder="Email"
												dataQA="email-input"
												id="email"
												name="email"
												error={errors.email}
												validators={{
													required: "An email is required",
													validate: {
														isEmail: (v) => {
															if (typeof v === "string") {
																return validateEmail(v) || "Invalid email address";
															}
															return `${JSON.stringify(v)} is not a string`;
														},
													},
												}}
												register={register}
												required
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<div>
												<div className="label-wrapper">
													<label
														className="text-input_label text-input_label__type-text"
														htmlFor="email-job"
													>
														Email Job
													</label>
													{watch("emailjob") !== defaultEmailJob && (
														<LinkButton
															buttonText="Reset"
															dataQA="reset-email-job"
															onClick={() => setValue("emailjob", defaultEmailJob)}
														/>
													)}
												</div>
												<Controller
													name="emailjob"
													control={control}
													render={({ field }) => (
														<Editor
															{...field}
															height="200px"
															id="email-job"
															dataQA="email-job-editor"
															onChange={(value) => setValue("emailjob", value)}
														/>
													)}
												/>
											</div>
										</Column>
									</Row>
									<Row>
										<Column>
											<div>
												<div className="label-wrapper">
													<label
														className="text-input_label text-input_label__type-text"
														htmlFor="overrideESPCreds"
													>
														Override ESP Creds
													</label>
													{watch("espCreds") !== DEFAULT_ESP_CREDS && (
														<LinkButton
															buttonText="Reset"
															dataQA="reset-esp-creds"
															onClick={() => setValue("espCreds", DEFAULT_ESP_CREDS)}
														/>
													)}
												</div>
												<Controller
													name="espCreds"
													control={control}
													render={({ field }) => (
														<Editor
															{...field}
															height="200px"
															id="overrideESPCreds"
															dataQA="override-esp-creds-editor"
															onChange={(value) => setValue("espCreds", value)}
														/>
													)}
												/>
											</div>
										</Column>
									</Row>
									<Row>
										<Button
											buttonText="Submit"
											variant="primary"
											dataQA="submit"
											type="submit"
										/>
									</Row>
								</Fields>
							</form>
						</div>
					</Body>,
			}}
		>
		</Navigation>

	);
};
export default SendEmail;
