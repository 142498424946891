import React from "react";
import {
	FieldError, FieldErrorsImpl, FieldValues, Merge, Path,
} from "react-hook-form";

import { Combobox } from "../form";
import { ComboboxProps } from "../form/Combobox";

import { formatWebsiteLabel } from "./parseWebsite";
import useWebsites from "./useWebsites";

export interface WebsitePickerProps<T> {
	control: ComboboxProps<T>["control"];
	error?: Merge<FieldError, FieldErrorsImpl<{ label: string; value: string; }>>;
	label: string;
}

const WebsitePicker = <T extends FieldValues>({
	control,
	label,
	error,
}: WebsitePickerProps<T>) => {
	const { getWebsites } = useWebsites();
	const websites = getWebsites();

	const name = "websiteid";

	return (
		<Combobox
			required
			errorMessage="Please select a website"
			dataQA="website-picker-select"
			label={label}
			placeholder="Website Name or ID..."
			error={error}
			name={name as Path<T & FieldValues>}
			control={control}
			options={websites?.map(({ id, name: websiteName }) => ({
				label: formatWebsiteLabel({ label: websiteName, value: id }),
				value: id,
			})) || []}
		/>
	);
};

export default WebsitePicker;
