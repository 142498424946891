import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Modal, ModalBody, ModalFooter, ModalHeader, ModalHeading, Toast, Typography,
} from "@frontend/wknd-components";

import { RootState } from "../app/store";

import { removeNotification } from "./notifications.slice";

import "./ToastProvider.scss";

const ToastProvider = ({ children }: React.PropsWithChildren) => {
	const MAX_NOTIFICATIONS = 10;
	const dispatch = useDispatch();
	const notifications = useSelector((state: RootState) => state.notifications.notifications);
	const [detailsShown, setDetailsShown] = useState<boolean>(false);
	const [details, setDetails] = useState<string>("");

	const hideDetails = () => {
		setDetailsShown(false);
	};

	const showDetails = (notificationDetails: string) => {
		setDetails(`${notificationDetails}`);
		setDetailsShown(true);
	};

	return (
		<>
			<div className="toast-container">
				<Modal
					isOpen={detailsShown}
					dataQA="notification-toast-details"
					contentLabel="Details"
					onRequestClose={hideDetails}
					variant="scrollable"
				>
					<ModalHeader dataQA="notification-toast-modal-header">
						<ModalHeading
							component="h2"
							dataQA="notification-toast-modal-heading"
							headingIcon="Info"
						>
							Details
						</ModalHeading>
					</ModalHeader>
					<ModalBody dataQA="notification-toast-modal-body">
						<Typography
							className="notification-toast-paragraphy"
							dataQA="notification-toast-paragraphy"
							variant="bodyCopy"
						>
							{details}
						</Typography>
					</ModalBody>
					<ModalFooter
						dataQA="notification-toast-modal-footer"
						primaryButtonText="OK"
						primaryButtonOnClick={() => hideDetails()}
						secondaryButtonText=""
						hideSecondaryButton
					/>
				</Modal>
				{notifications.map((notification, index) => (
					index >= MAX_NOTIFICATIONS ? null :
						<div key={notification.id}>
							<Toast
								className="notification-toast"
								dataQA="notification-toast"
								showIcon
								variant={notification.variant}
								text={notification.text}
								hasTimeout={notification.hasTimeout}
								timeout={notification.timeout}
								trailingLinkText={notification.details && notification.trailingLinkText}
								trailingLinkAction={() => showDetails(notification.details || "")}
								onDismiss={() => dispatch(removeNotification(notification.id))}
							/>
						</div>
				))}
			</div>
			{children}
		</>
	);
};

export default ToastProvider;
