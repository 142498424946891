import React, { PropsWithChildren } from "react";

import { WithDataQa } from "@frontend/thorium232/dist/types/base";

const ListRowHeader = ({ children, dataQa = "list-row-header" }: WithDataQa & PropsWithChildren) => (
	<div className="list-row-header" data-qa={dataQa}>{children}
	</div>
);

export default ListRowHeader;
