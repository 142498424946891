import React from "react";

import { Column, Row } from "@frontend/thorium232/dist/fieldset";
import { Badge, Tabs, TabsContent, TabsList, TabsTrigger, Typography } from "@frontend/wknd-components";

import { GetEligibilityResponse } from "./types";

interface EligibilityResultProps {
	result?: GetEligibilityResponse
}

interface TabData {
	value: string
	text?: string,
	content?: JSX.Element,
}

const EligibilityResults = ({
	result,
}: EligibilityResultProps) => {

	const tabsData: TabData[] = [
		{
			value: "full-results",
			text: "Full Results",
			content: (<Row>
				<Column>
					<pre>
						<code className="eligibility-result-code" data-qa="full-results-code">
							{JSON.stringify(result, null, 4)}
						</code>
					</pre>
				</Column>
			</Row>),
		},
		{
			value: "test-fixure",
			text: "Test Fixture",
			content: (
				<Row>
					<Column>
						<pre>
							<code className="eligibility-result-code" data-qa="test-fixture-code">
								{JSON.stringify(result?.vars, null, 4)}
							</code>
						</pre>
					</Column>
				</Row>),
		},
	];


	return (
		<div className="eligibility-results-wrapper">
			<Row dataQa="eligibility-called-wrapper">
				<Typography
					dataQA={"eligibility-called"}
					component="h1"
					variant="headline"
					className="eligibility-called">
					Eligible Called
				</Typography>
				<Badge
					dataQA={result?.eligiblecalled ? "active-badge" : "error-badge"}
					text={result?.eligiblecalled ? "Yes" : "No"}
					variant={result?.eligiblecalled ? "active" : "error"} />
			</Row>
			<Row dataQa="ineligibility-called-wrapper">
				<Typography
					dataQA={"ineligibility-called"}
					component="h1"
					variant="headline"
					className="ineligibility-called">
					Ineligible Called
				</Typography>
				<Badge
					dataQA={result?.ineligiblecalled ? "active-badge" : "error-badge"}
					text={result?.ineligiblecalled ? "Yes" : "No"}
					variant={result?.ineligiblecalled ? "active" : "error"} />
			</Row>

			<Tabs defaultValue={tabsData[0].value} dataQA="eligibility-results-tabs" className="eligibility-results-tabs">
				<TabsList aria-label="tabs example" dataQA="eligibility-results-tabs-list">
					{tabsData.map((tab) => (
						<TabsTrigger
							key={tab.value}
							value={tab.value}
							dataQA={`eligibility-results-tabs-${tab.value}-trigger`}
						>
							{tab.text}
						</TabsTrigger>
					))}
				</TabsList>
				{tabsData.map((tab) => (
					<TabsContent
						key={tab.value}
						value={tab.value}
						dataQA="eligibility-results-tabs-content"
						className="eligibility-results-tabs-content"
					>
						{tab.content}
					</TabsContent>
				))}
			</Tabs>
		</div>
	);
};

export default EligibilityResults;
