import React, { MouseEventHandler, PropsWithChildren } from "react";
import classnames from "classnames";

import { SizeOptions, WithDataQa } from "@frontend/thorium232/dist/types/base";

import ListRowHeader from "./ListRowHeader";

interface ListColumnProps extends WithDataQa, PropsWithChildren {
	size?: SizeOptions;
	headerStyle?: "inline" | "stacked";
	onClick?: MouseEventHandler;
	flush?: "right" | "left";
	action?: boolean;
	hidden?: boolean;
	id?: string;
}

const ListColumn = ({
	size,
	action,
	flush,
	hidden,
	onClick,
	children,
	headerStyle,
	dataQa = "list-column",
	id,
}: ListColumnProps) => {
	const childrenWrap = headerStyle === "inline" ? (
		<ListRowHeader>
			{children}
		</ListRowHeader>
	) : children;

	return (
		<div
			onClick={onClick}
			id={id || ""}
			className={classnames(
				"list_col",
				{
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					[`list_col__${size}`]: size,
					list_col__action: action,
					"list_col__header-style-stacked": headerStyle === "stacked",
					list_col__ghost: hidden,
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					[`list_col__flush-${flush}`]: flush,
				},
			)}
			role="presentation"
			data-qa={dataQa}
		>
			{childrenWrap}
		</div>
	);
};

export default ListColumn;
