import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import { DataTable, LinkButton, Typography } from "@frontend/wknd-components";
import { AnyAction } from "@reduxjs/toolkit";

import { useDetachPrimaryIDMutation } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import DialogModal from "../form/DialogModal";
import { appendNotification } from "../notifications/notifications.slice";
import useWebsites from "../websites/useWebsites";

import { PrivacySearchTypes } from "./privacy.slice";
import { StyledDetachButton, StyledResults, StyledWebsiteName } from "./PrivacyIdLookUp.styles";
import { DetachPrimaryIdRequest, DetachPrimaryIdResponse, DevicesFromPrivacyId } from "./types";


interface ResultsProps {
	results: DevicesFromPrivacyId[],
	title: string,
	input?: string,
	searchType?: PrivacySearchTypes
	updateResultsToRemoveRecord?: (rowId: string) => void
}

const typeValuesMap = new Map<number, string>([
	[0, "Unspecified"],
	[1, "BX DID"],
	[2, "Soft ID"],
	[3, "Email Hash"],
	[4, "Phone Hash"],
	[5, "Device Token"],
	[6, "Privacy ID"],
	[7, "Klaviyo ID"],
	[8, "Attentive ID"],
	[9, "BlueConic ID"],
	[10, "Loyalty Plus ID"],
	[11, "Customer ID"],
]);

const PrivacyIdLookUpResults = ({ results, title, input, searchType, updateResultsToRemoveRecord }: ResultsProps) => {
	const navigate = useNavigate();
	const { getWebsiteById } = useWebsites();

	const dispatch = useDispatch();

	const [detachPrimaryID] = useDetachPrimaryIDMutation();

	const [modalOpen, setModalOpen] = useState(false);
	const [websiteIdToDetach, setWebsiteIdToDetach] = useState("");
	const [deviceIdToDetach, setDeviceIdToDetach] = useState("");

	const onModalClose = () => {
		setModalOpen(false);
	};

	const onModalConfirm = async () => {
		if (input && searchType) {
			let detachPrimaryIdRequest: DetachPrimaryIdRequest = {
				websiteid: websiteIdToDetach,
				deviceid: deviceIdToDetach,
			};
			if (searchType === PrivacySearchTypes.EMAIL) {
				detachPrimaryIdRequest.email = input;
			} else if (searchType === PrivacySearchTypes.PHONE) {
				detachPrimaryIdRequest.phone = input;
			} else if (searchType === PrivacySearchTypes.EMAIL_HASH) {
				detachPrimaryIdRequest.emailhash = input;
			} else if (searchType === PrivacySearchTypes.PHONE_HASH) {
				detachPrimaryIdRequest.phonehash = input;
			}

			try {
				const resp: DetachPrimaryIdResponse = await detachPrimaryID(detachPrimaryIdRequest).unwrap();
				if (resp.success) {
					dispatch(appendNotification({
						id: 0,
						text: `${searchType} ${input} successfully detached from device ID ${deviceIdToDetach}`,
						variant: "Validation",
						hasTimeout: false,
					}) as unknown as AnyAction);
					if (updateResultsToRemoveRecord) {
						updateResultsToRemoveRecord(deviceIdToDetach);
					}
				} else {
					dispatch(appendNotification({
						id: 0,
						text: `${searchType} ${input} was not detached from device ID ${deviceIdToDetach}`,
						variant: "Error",
						hasTimeout: false,
					}) as unknown as AnyAction);
				}
			} catch (error) {
				return catchErrorReturnString();
			}

		}
		setModalOpen(false);
	};

	const handleDetachPrimaryID = (websiteId: string, deviceId: string) => {
		setModalOpen(true);
		setWebsiteIdToDetach(websiteId);
		setDeviceIdToDetach(deviceId);
	};

	return (
		<>
			<DialogModal
				isOpen={modalOpen}
				onClose={onModalClose}
				onConfirm={onModalConfirm}
				onDeny={onModalClose}
				size="medium"
				label="Confirm Detach from Device ID"
				bodyText={`Are you sure you want to detach ${searchType ? searchType : "SEARCH_TYPE"}
				${input ? input : "INPUT"} from device ID ${deviceIdToDetach} in PrivacyID? This action is not reversible.`}
			/>
			<StyledResults>
				<Typography dataQA="text" variant="headline" mb="10px">{title}</Typography>
				<DataTable
					columns={[
						{
							name: "ID",
							cell: (row: DevicesFromPrivacyId) => {
								if (row?.type === 1 && row?.websiteid) {
									const stringifiedURLparams = createSearchParams({
										searchInput: row.id,
										websiteId: row.websiteid.toString(),
										searchType: PrivacySearchTypes.DEVICE_ID,
									}).toString();
									return (
										<LinkButton
											aria-label="device id"
											dataQA="device-id-result"
											buttonText={row.id}
											onClick={() => navigate({ pathname: "/results", search: stringifiedURLparams })}
										/>
									);
								}
								return (<Typography dataQA="" variant="bodyCopy">{row.id} </Typography>);

							},

						},
						{
							name: "ID Type",
							cell: (row: DevicesFromPrivacyId) => (
								<Typography dataQA="" variant="bodyCopy"> {
									typeValuesMap.get(row.type)} </Typography>
							),
						},
						{
							name: "Website Info",
							cell: (row: DevicesFromPrivacyId) => {
								let websiteName = "";
								let websiteId = row.websiteid?.toString() || "Shared";
								if (websiteId) {
									websiteName = getWebsiteById(websiteId)?.name ?? "";
								}

								return (
									<StyledWebsiteName>
										{websiteName && (
											<Typography dataQA="" variant="bodyCopyBold"> {websiteName}&nbsp;&nbsp;</Typography>
										)}
										<Typography dataQA="" variant="bodyCopy"> {websiteId}</Typography>
									</StyledWebsiteName>

								);
							},
						},
						{
							name: "Device Score",
							selector: (row: DevicesFromPrivacyId) => row.device_score || "-",
						},
						{
							name: "Website Score",
							selector: (row: DevicesFromPrivacyId) => row.website_score || "-",
						},
						{
							name: "Graph Score",
							selector: (row: DevicesFromPrivacyId) => row.graph_score || "-",
						},
						{
							name: "",
							selector: (row: DevicesFromPrivacyId) => {
								if (row?.type === 1 && input && searchType
									&& [PrivacySearchTypes.EMAIL, PrivacySearchTypes.EMAIL_HASH,
										PrivacySearchTypes.PHONE, PrivacySearchTypes.PHONE_HASH].indexOf(searchType) > -1) {
									const idType = searchType == PrivacySearchTypes.EMAIL || searchType == PrivacySearchTypes.EMAIL_HASH ?
										"Email" : "Phone";
									const buttonText = `Detach ${idType} from Device ID`;
									const websiteId = row?.websiteid?.toString() || "";

									return (
										<StyledDetachButton
											variant="primary"
											primaryButtonColor="destructive"
											dataQA=""
											buttonText={buttonText}
											onClick={() => handleDetachPrimaryID(websiteId, row.id)}
										/>
									);
								}
							},
						},
					]}
					data={results}
					dataQA="id-results"
					rowSpacing="snug"
				/>
			</StyledResults>
		</>

	);
};

export default PrivacyIdLookUpResults;
