import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

import { FilterBys, SearchTypes } from "./search.slice";

/**
 * Get All Features
 * @param state
 * @returns FeatureState
 */
export const getSearchState = (state: RootState) => state.search;
export const getResult = (state: RootState) => state.search.result;
export const getFilters = (state: RootState) => state.search.filters;
export const getFilteredResult = (state: RootState) => state.search.filteredResult;
export const getInitialSearch = (state: RootState) => state.initialSearch;
export const getDateFilters = (state: RootState) => state.search.datetimeFilter;

const isEmailType = (type: SearchTypes) => type === SearchTypes.EMAIL;
const isEmailHashType = (type: SearchTypes) => type === SearchTypes.EMAIL_HASH;
const isDeviceType = (type: SearchTypes) => type === SearchTypes.DEVICE_ID;
const isPhoneType = (type: SearchTypes) => type === SearchTypes.PHONE || type === SearchTypes.PHONE_HASH;

export const isType = (type: SearchTypes, checkAgainst: "email" | "email_hash" | "device" | "phone") => {
	switch (checkAgainst) {
		case "email":
			return isEmailType(type);
		case "email_hash":
			return isEmailHashType(type);
		case "device":
			return isDeviceType(type);
		case "phone":
			return isPhoneType(type);
		default:
			return false;
	}
};

export const filterTypesMap = new Map<string, FilterBys>([
	["eventType", FilterBys.EVENT_TYPE],
	["campaignid", FilterBys.CAMPAIGN_ID],
]);

export const advancedFilterTypesMap = new Map<string, FilterBys>([
	["deviceid", FilterBys.DEVICE_ID],
	["itemid", FilterBys.ITEM_ID],
	["visitid", FilterBys.VISIT_ID],
	["eventid", FilterBys.EVENT_ID],
]);

export const allFilterTypesMap = new Map([
	...Array.from(filterTypesMap.entries()),
	...Array.from(advancedFilterTypesMap.entries()),
]);


export const getParsedSearchState = createSelector(
	getSearchState,
	({ websiteid, websitename, input, type }) => ({
		websiteid,
		websitename,
		email: isType(type, "email") ? input : "",
		deviceid: isType(type, "device") ? input : "",
		type: type,
		inputVal: input,
	}),
);
