import React from "react";
import {
	createBrowserRouter,
	Navigate,
	Outlet,
	useMatch,
	useSearchParams,
} from "react-router-dom";

import { Body } from "@frontend/thorium232/dist/layout";

import { Artifacts } from "../artifacts";
import BatchSendEmail from "../batch-send-email/BatchSendEmail";
import CustomerStateCheck from "../customer-state-check/CustomerStateCheck";
import Eligibility from "../eligibility/Eligibility";
import EspIDToEmail from "../esp-id-to-email/EspIdToEmail";
import {
	HeaderBar,
	Navigation, Tabs,
} from "../navigation";
import PrivacyIdLookUp from "../privacy-id-look-up/PrivacyIdLookUp";
import Search from "../search/Search";
import SearchResultsPage from "../search/SearchResultsPage";
import SendEmail from "../send-email/SendEmail";
import TriggerEvents from "../trigger-events/TriggerEvents";
import UnsubscribeEmails from "../unsubscribe-emails/UnsubscribeEmails";

import ErrorBoundary from "./ErrorBoundary";

import "../App.scss";

const ToolsRedirect = () => {
	const { pathname = "" } = useMatch(window.location.pathname) || {};

	if (pathname === "/tools" || pathname === "/tools/") {
		return <Navigate to="/tools/trigger-events" replace />;
	}
	return null;
};

const ResultsRedirect = () => {
	const { pathname = "" } = useMatch(window.location.pathname) || {};
	const [searchParams] = useSearchParams();

	if (pathname === "/results" || pathname === "/results/" || pathname === "/") {
		return <Navigate to={`/search?${searchParams.toString()}`} replace />;
	}
	return null;
};

export default createBrowserRouter([
	{
		path: "/",
		element:
			<>
				<Tabs />
				<ResultsRedirect />
			</>,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "/search",
		element:
			<>
				<Tabs />
				<Navigation
					layout={{
						documentTitle: "UHD 2.0 | Search",
						headerBar:
							<HeaderBar>
								<Search />
							</HeaderBar>,
						body:
							<Body maxWidth={false}>
								<SearchResultsPage />
							</Body>,
						leftSideBar:
							<Artifacts />,
					}}
				/>
			</>,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "/results",
		element: (
			<>
				<Tabs />
				<ResultsRedirect />
			</>
		),
	},
	{
		path: "/tools",
		element: (
			<>
				<Tabs />
				<ToolsRedirect />
				<Outlet />
			</>
		),
		children: [{
			index: true,
			path: "trigger-events",
			element: <TriggerEvents />,
		},
		{
			path: "send-email",
			element: <SendEmail />,
		},
		{
			path: "batch-send-email",
			element: <BatchSendEmail />,
		},
		{
			path: "esp-id",
			element: <EspIDToEmail />,
		},
		{
			path: "eligibility",
			element: <Eligibility />,
		},
		{
			path: "customer-state-check",
			element: <CustomerStateCheck />,
		},
		{
			path: "unsubscribe-tool",
			element: <UnsubscribeEmails />,
		},
		{
			path: "privacy-id",
			element: <PrivacyIdLookUp />,
		}],
	}]);

