import styled from "styled-components";

import { Card } from "@frontend/wknd-components";

export const StyledCard = styled(Card)`
	width: 100%;
	margin-top: 15px;
	margin-bottom: 10px;
`;

export const DivSearchFields = styled.div`
	width: 29%;
	margin-right: 1%;
`;

export const StyledRow = styled.div`
	align-items: flex-start;
	display:flex;
`;

export const SearchButtonWrapper = styled.div`
	display: grid;
	width: 9%;
`;
