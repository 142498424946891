import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Column, Fields, Row } from "@frontend/thorium232/dist/fieldset";
import { Body } from "@frontend/thorium232/dist/layout";
import { SizeOptions } from "@frontend/thorium232/dist/types/base";
import { AlertBanner, Button, Checkbox, colors, Typography } from "@frontend/wknd-components";

import { useLazyGetSetUnsubscribeQuery } from "../app/api";
import { catchErrorReturnString } from "../app/errorHandling";
import { GetUnsubscribeCheckResponse } from "../artifacts/types";
import DialogModal from "../form/DialogModal";
import Input from "../form/Input";
import { Navigation } from "../navigation";
import { getParsedSearchState } from "../search/search.selector";
import {
	setWebsite,
} from "../search/search.slice";
import { formatWebsiteLabel } from "../websites/parseWebsite";
import WebsitePicker from "../websites/WebsitePicker";


const StyledDivider = styled.div`
	background-color: ${colors.lightGray};
	width: 100%;
	height: 1px;
	margin: 8px 0;
`;

const UnsubscribeCheckbox = styled(Checkbox)`
	label {
		font-weight: 700;
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}
`;

const StyledAlertBanner = styled(AlertBanner)`
	box-sizing: initial;
`;

const UnsubscribeCheckWrapper = styled.div`
	width: 40rem;
`;

interface UnsubscribeEmailsForm {
	email: string;
	websiteid: {
		label: string;
		value: string;
	};
	disableEspApiCheckbox: boolean;
	unsubscribeUserCheckbox: boolean;
}

interface UnsubscribeResult {
	order: number;
	result: GetUnsubscribeCheckResponse
}

const UnsubscribeEmails = () => {
	const dispatch = useDispatch();
	const [getUnsubStatus] = useLazyGetSetUnsubscribeQuery();
	const [unsubResults, setUnsubResults] = useState<UnsubscribeResult[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const {
		websiteid: websiteidFromSearch,
		websitename: websitenameFromSearch,
		email: emailFromSearch,
	} = useSelector(getParsedSearchState);
	const [emailArrayToString, setemailArrayToString] = useState(emailFromSearch);

	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			email: "",
			websiteid: {
				value: websiteidFromSearch,
				label: formatWebsiteLabel({ label: websitenameFromSearch, value: websiteidFromSearch }),
			},
			disableEspApiCheckbox: true,
			unsubscribeUserCheckbox: false,
		},
	});


	const getEmails = () => emailArrayToString.split(",").filter((e) => e != "");

	const getUnsubResults = async (data: UnsubscribeEmailsForm) => {
		const { websiteid, disableEspApiCheckbox, unsubscribeUserCheckbox } = data;
		const results: UnsubscribeResult[] = [];

		await Promise.all(getEmails().map(async (e: string, index: number) => {
			try {
				const res = await getUnsubStatus({
					email: e.trim(),
					websiteid: websiteid.value,
					disable_cache: disableEspApiCheckbox ? "1" : "0",
					should_unsubscribe: unsubscribeUserCheckbox ? "1" : "0",
				}).unwrap();
				if (res.success) {
					results.push({ order: index, result: res });
				}
			} catch {
				return catchErrorReturnString();
			}

		}));

		setUnsubResults(results);
	};

	const submitLookUp = async (data: UnsubscribeEmailsForm) => {
		if (data.unsubscribeUserCheckbox) {
			setModalOpen(true);
			return;
		}

		await getUnsubResults(data);

		dispatch(setWebsite({
			id: data.websiteid.value,
			name: data.websiteid.label,
		}));
	};

	const onModalClose = () => {
		setValue("unsubscribeUserCheckbox", false);
		setModalOpen(false);
		getUnsubResults(getValues());
	};

	const onModalConfirm = () => {
		getUnsubResults(getValues());
		setModalOpen(false);
	};

	unsubResults.sort(({ order: a }, { order: b }) => a > b ? 1 : -1);

	const isUnsubscribedText = (
		result: GetUnsubscribeCheckResponse,
	) => String(result.IsUnsubscribed) + (result.IsUnsubscribedCacheHit ? " (from cache: true)" : "");

	return (
		<Navigation
			layout={{
				documentTitle: "UHD 2.0 | Unsubscribe Tool",
				body:
					<Body maxWidth={false}>
						<DialogModal
							isOpen={modalOpen}
							onClose={onModalClose}
							onConfirm={onModalConfirm}
							onDeny={onModalClose}
							label="Confirm Unsubscribe"
							bodyText={`Are you sure you want to unsubscribe ${getValues("email") || "the user"}?\n
							Hit No to leave them subscribed!`}
						/>
						<UnsubscribeCheckWrapper>
							<form onSubmit={handleSubmit(submitLookUp)}>
								<Fields>
									<Row>
										<Column>
											<WebsitePicker
												control={control}
												error={errors.websiteid}
												label="Website ID / Name"
											/>
										</Column>
									</Row>
									<Row>
										<Column>
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange } }) => (
													<Input
														name="email"
														label="Email"
														placeholder="Enter email address(es) separated by comma.."
														register={register}
														error={errors.email}
														id="emails"
														dataQA="email-input"
														isTagsInput={true}
														value={getEmails()}
														validationString="Press comma or Enter after each email.
												You can enter up to three emails"
														validators={{
															validate: {
																isThreeEmailsOrLess: () => {
																	if (getEmails().length > 3) {
																		return "Email input accepts a max of 3 emails separated by comma";
																	}
																},
															},
														}}
														// using state instead of updating react-hook-form, as the value reflects the string/text input and not the tag values
														onChange={({ tags }) => {
															if (tags) {
																const tagsString = tags.length > 0 ? tags.join(",") : "";
																return onChange(tags && setemailArrayToString(tagsString));
															}
														}}
													/>
												)}
											/>
										</Column>
									</Row>
									<Row>
										<Column size={SizeOptions.L}>
											<Controller
												name="disableEspApiCheckbox"
												control={control}
												render={({ field: { onChange, value } }) => (
													<Checkbox
														onChange={onChange}
														checked={value}
														label="Disable ESP API Cache"
														dataQA="disable-esp-api-checkbox"
													/>
												)}
											/>
										</Column>
									</Row>
									<Row>
										<Column size={SizeOptions.L}>
											<Controller
												name="unsubscribeUserCheckbox"
												control={control}
												render={({ field: { onChange, value } }) => (
													<UnsubscribeCheckbox
														onChange={onChange}
														checked={value}
														label="Check to unsubscribe"
														dataQA="unsubscribe-user-checkbox"
													/>
												)}
											/>
										</Column>
									</Row>
									{!!watch("unsubscribeUserCheckbox") && (
										<Row>
											<Column size={SizeOptions.XL}>
												<StyledAlertBanner dataQA="without-dismiss-alert-banner" variant="error">
													<Typography dataQA="without-dismiss-alert-banner-text" variant="bodyCopy">
														{`This will unsubscribe ${getValues("email") || "the user"}.
																Uncheck this box if you only want to check users status.`}
													</Typography>
												</StyledAlertBanner>

											</Column>
										</Row>
									)}

									<Row>
										<Column size={SizeOptions.S}>
											<Button
												buttonText="Search"
												variant="primary"
												dataQA="search-button"
												type="submit"
												mt="20px"
											/>
										</Column>
									</Row>
									{unsubResults.map(({ result }, index) => (
										<div key={index} data-qa="unsub-results-div">
											<Row>
												<Column>
													<Typography dataQA={`${index}-result-email-header`} variant="bodyCopyBold">
														Email:
													</Typography>
												</Column>
												<Column>
													<Typography dataQA={`${index}-result-email`} variant="bodyCopySmall">
														{result.email}
													</Typography>
												</Column>
											</Row>
											<Row>
												<Column>
													<Typography dataQA={`${index}-result-isunubscribed-header`} variant="bodyCopyBold">
														IsUnsubscribed:
													</Typography>
												</Column>
												<Column>
													<Typography dataQA={`${index}-result-isunubscribed`} variant="bodyCopySmall">
														{isUnsubscribedText(result)}
													</Typography>
												</Column>
											</Row>
											<Row>
												<Column>
													<Typography dataQA={`${index}-result-isunubscribedibx1-header`} variant="bodyCopyBold">
														IsUnsubscribedIbx1:
													</Typography>
												</Column>
												<Column>
													<Typography dataQA={`${index}-result-isunubscribedibx1`} variant="bodyCopySmall">
														{String(result.IsUnsubscribedIbx1)}
													</Typography>
												</Column>
											</Row>
											<StyledDivider />
										</div>

									))}
								</Fields>
							</form>
						</UnsubscribeCheckWrapper>
					</Body>,
			}}
		/>


	);
};

export default UnsubscribeEmails;
